import Modal from "react-responsive-modal";
import { PATHS, STATUS_TYPES } from "../../data";
import { usePostSubmitFormMutation } from "../../api";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../redux/slices";
import { useRouter } from "../../hooks";

interface IModal {
    open: boolean;
    closeModal: () => void;
}

const FinalSubmitModal = (props: IModal) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const [postSubmitForm, { isLoading }] = usePostSubmitFormMutation();
    const bank = useSelector((state: RootState) => state.auth.bank);
    const isFormApproved = bank?.status === STATUS_TYPES.APPROVED;

    const { open, closeModal } = props;
    const minWidth = "40vw";

    const handleClose = () => {
        closeModal();
    };

    const handleConfirm = () => {

        /* CREATE PAYLOAD */
        const payload = new FormData();
        payload.append("status", STATUS_TYPES.SUBMITTED);

        /* SUBMIT FORM */
        postSubmitForm({ bankId: bank?.id, payload })
            .unwrap()
            .then(() => {
                dispatch(showToast({ message: "Your form has been submitted.", type: 'success' }));
                handleClose();

                /* NAVIGATE TO DASHBOARD */
                router.push(PATHS.DASHBOARD);
            })
            .catch((error) => dispatch(showToast({ message: error?.data?.message || "Failed", type: 'error' })));
        handleClose();
    };

    return (
        <Modal center open={open} onClose={handleClose} onEscKeyDown={handleClose} showCloseIcon styles={{ modal: { minWidth } }} classNames={{ modal: "custom-modal" }}>
            <form onSubmit={() => handleConfirm()}>

                {/* HEADER */}
                <div className="title-box">
                    <h5>Confirmation</h5>
                </div>

                {/* BODY */}
                <div className="row">
                    <div className="col-12">
                        <p className="final-text">Do you want to go ahead and submit the form?</p>
                    </div>
                </div>

                {/* FOOTER */}
                <div className="modal-btn-footer">
                    <button type="button" className="line-btn btn" onClick={handleClose}>Cancel</button>
                    <button type="button" title="Confirm" disabled={isLoading || isFormApproved} className="btn common-btn" onClick={() => handleConfirm()}>
                        {isLoading && <div className="submit-button-loader" />}
                        Yes, Submit
                    </button>
                </div>

            </form>
        </Modal>
    );
};

export default FinalSubmitModal;

import DocumentPreview from "../document-preview/DocumentPreview";
import Loader from "../loader/Loader";

interface IProps {
    companyDetails: {
        bankName: string;
        cin: string;
        pan: string;
        gst: string;
        cinDocId: string;
        panDocId: string;
        gstDocId: string;
        empCertDocId: string;
        logo: string;
    }
    isLoading: boolean
}

const CompanyDetailsSection = ({ companyDetails, isLoading }: IProps) => {

    return (
        <div className="common-data-box">
            <h6 className="data-title-box">
                Company Details
            </h6>
            {isLoading ?
                <Loader /> :
                <>
                    <div className="data-box">
                        <div className="left-box">
                            <span>Organization Name</span>
                            <p>{companyDetails?.bankName ?? "-"}</p>
                        </div>
                        <div className="right-box">
                            <span>Company Incorporation Number</span>
                            <p>{companyDetails?.cin ?? "-"}</p>
                        </div>
                    </div>
                    <div className="data-box">
                        <div className="left-box">
                            <span>GST</span>
                            <p>{companyDetails?.gst ?? "-"}</p>
                        </div>
                        <div className="right-box">
                            <span>PAN</span>
                            <p>{companyDetails?.pan ?? "-"}</p>
                        </div>
                    </div>
                    
                    {/* COMPANY DETAILS DOCUMENT */}

                    <div className="document-data-box">
                        <div className="common-block-box company-details-documents mb-3">
                            <DocumentPreview
                                title="Company Incorporation Cert."
                                id={companyDetails?.cinDocId}
                                fileName="Company Incorporation Certificate.pdf"
                            />
                            <DocumentPreview
                                title="PAN Card"
                                id={companyDetails?.panDocId}
                                fileName="PAN Card.pdf"
                            />
                            <DocumentPreview
                                title="GST Certificate"
                                id={companyDetails?.gstDocId}
                                fileName="GST Certificate.pdf"
                            />
                            <DocumentPreview
                                title="Employee Certificate"
                                id={companyDetails?.empCertDocId}
                                fileName="Employee Certificate.pdf"
                            />
                            <DocumentPreview
                                title="Company Logo"
                                id={companyDetails?.logo}
                                fileName="logo.jpg"
                                isImage={true}
                            />
                        </div>
                    </div>
                </>
            }

        </div>
    )
}

export default CompanyDetailsSection;

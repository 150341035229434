import * as Yup from "yup";
import { FILE_EXTENSION_TYPES, PDF_FILE_SIZE_VALIDATIONS, FILE_TYPES, ID_TYPES, IMG_FILE_SIZE_VALIDATIONS } from "../data";

/* COMMON REGEX */
// const onlyUppercaseLettersRegex = /^[A-Z]+$/;
// const onlyCharactersRegex = /^[A-Za-z]+$/;

const noSpecialCharacterAllowedRegex = /^[A-Za-z0-9 ]+$/;
const validEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const onlyNoRegex = /^[0-9]+$/;
const validIndianMobileNoRegex = /^[6-9]\d{9}$/;
const noSpaceAllowedRegex = /^\S*$/

const atLeastOneUppercaseRegex = /[A-Z]/
const atLeastOneLowercaseRegex = /[a-z]/
const atLeastOneNoRegex = /[0-9]/
const atLeastOneSpecialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/

/* DOCUMENT NO REGEX */
const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const pancardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/
const cinRegex = /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
const aadharcardRegex = /^[0-9]{12}$/
const passportRegex = /^[A-Z0-9]{8,9}$/

/* RESET PASSWORD FORM */
export const resetPasswordValSchema = Yup.object({
    tmppwd: Yup
        .string()
        .matches(noSpaceAllowedRegex, 'No spaces are allowed')
        .required("Required!"),

    newpwd: Yup.string()
        .required("Required!")
        .min(8, "Password must be at least 8 characters long")
        .matches(noSpaceAllowedRegex, 'No spaces are allowed')
        .matches(atLeastOneUppercaseRegex, "Password must contain at least one uppercase letter")
        .matches(atLeastOneLowercaseRegex, "Password must contain at least one lowercase letter")
        .matches(atLeastOneNoRegex, "Password must contain at least one number")
        .matches(atLeastOneSpecialCharacterRegex, "Password must contain at least one special character"),

    repwd: Yup.string()
        .required("Please confirm your new password")
        .oneOf([Yup.ref('newpwd')], "Passwords must match")
});

/* LOGIN FORM */
export const loginValSchema = Yup.object({
    username: Yup
        .string()
        .matches(validEmailRegex, 'Invalid email format')
        .required('Required!'),

    password: Yup
        .string()
        .required('Required!')
        .min(8, "Password must be at least 8 characters long")
        .matches(noSpaceAllowedRegex, 'No spaces are allowed')
        .matches(atLeastOneUppercaseRegex, "Password must contain at least one uppercase letter")
        .matches(atLeastOneLowercaseRegex, "Password must contain at least one lowercase letter")
        .matches(atLeastOneNoRegex, "Password must contain at least one number")
        .matches(atLeastOneSpecialCharacterRegex, "Password must contain at least one special character"),
});

/* COMPANY DETAILS FORM */
export const companyDetailsValSchema = Yup.object({
    gst: Yup.string()
        .required('Required!')
        .matches(noSpaceAllowedRegex, 'No spaces are allowed')
        .matches(gstinRegex, 'GST must be 15 characters long and follow the format: 22AAAAA0000A1Z5'),
    cin: Yup.string()
        .required('Required!')
        .matches(noSpaceAllowedRegex, 'No spaces are allowed')
        .matches(cinRegex, 'Invalid CIN. It must be 21 characters long, starting with L or U, followed by 5 digits, 2 letters, 4 digits, 3 letters, and ending with 6 digits. follow the format: L17110MH1973PLC019786'),
    pan: Yup.string()
        .required('Required!')
        .matches(noSpaceAllowedRegex, 'No spaces are allowed')
        .matches(pancardRegex, 'PAN must be in the format AAAAA9999A')
        .length(10, 'PAN must be exactly 10 characters'),
    empCode: Yup.string()
        .required('Required!')
        .matches(noSpaceAllowedRegex, 'No spaces are allowed'),
    // .matches(empCodeRegex, 'Employee Code must be exactly 10 alphanumeric characters')
    // .length(10, 'Employee Code must be exactly 10 characters'),
    cinDocId: Yup.mixed()
        .required('Required!')
        .test('fileType', 'Only PDF files are allowed!', function (value) {
            /* Access test context via 'this  */
            if (typeof value === 'string') {
                return !!value.trim() || this.createError({ message: 'Field cannot be empty!' });
            } else if (value instanceof File) {

                const validMimeType = value.type?.toLowerCase() === FILE_TYPES.PDF.toLowerCase();
                const validExtension = value.name.toLowerCase().endsWith(FILE_EXTENSION_TYPES.PDF.toLowerCase());

                /* VALID MEME TYPE */
                if (!validMimeType || !validExtension) {
                    return this.createError({ message: 'Only PDF files are allowed!' });
                }

                /* MINIMU SIZE */
                if (value.size < PDF_FILE_SIZE_VALIDATIONS.MINIMUM_SIZE) {
                    return this.createError({ message: 'File size must be at least 30KB!' });
                }

                /* MAXIMUM SIZE */
                if (value.size > PDF_FILE_SIZE_VALIDATIONS.MAXIMUM_SIZE) {
                    return this.createError({ message: 'File size cannot exceed 100KB!' });
                }

                /* VALIDATION PASSED */
                return true;
            }
            return this.createError({ message: 'Invalid value type!' });
        }),

    panDocId: Yup.mixed()
        .required('Required!')
        .test('fileType', 'Only PDF files are allowed!', function (value) {
            /* Access test context via 'this  */
            if (typeof value === 'string') {
                return !!value.trim() || this.createError({ message: 'Field cannot be empty!' });
            } else if (value instanceof File) {

                const validMimeType = value.type?.toLowerCase() === FILE_TYPES.PDF.toLowerCase();
                const validExtension = value.name.toLowerCase().endsWith(FILE_EXTENSION_TYPES.PDF.toLowerCase());

                /* VALID MEME TYPE */
                if (!validMimeType || !validExtension) {
                    return this.createError({ message: 'Only PDF files are allowed!' });
                }

                /* MINIMU SIZE */
                if (value.size < PDF_FILE_SIZE_VALIDATIONS.MINIMUM_SIZE) {
                    return this.createError({ message: 'File size must be at least 30KB!' });
                }

                /* MAXIMUM SIZE */
                if (value.size > PDF_FILE_SIZE_VALIDATIONS.MAXIMUM_SIZE) {
                    return this.createError({ message: 'File size cannot exceed 100KB!' });
                }

                /* VALIDATION PASSED */
                return true;
            }
            return this.createError({ message: 'Invalid value type!' });
        }),

    gstDocId: Yup.mixed()
        .required('Required!')
        .test('fileType', 'Only PDF files are allowed!', function (value) {
            /* Access test context via 'this  */
            if (typeof value === 'string') {
                return !!value.trim() || this.createError({ message: 'Field cannot be empty!' });
            } else if (value instanceof File) {

                const validMimeType = value.type?.toLowerCase() === FILE_TYPES.PDF.toLowerCase();
                const validExtension = value.name.toLowerCase().endsWith(FILE_EXTENSION_TYPES.PDF.toLowerCase());

                /* VALID MEME TYPE */
                if (!validMimeType || !validExtension) {
                    return this.createError({ message: 'Only PDF files are allowed!' });
                }

                /* MINIMU SIZE */
                if (value.size < PDF_FILE_SIZE_VALIDATIONS.MINIMUM_SIZE) {
                    return this.createError({ message: 'File size must be at least 30KB!' });
                }

                /* MAXIMUM SIZE */
                if (value.size > PDF_FILE_SIZE_VALIDATIONS.MAXIMUM_SIZE) {
                    return this.createError({ message: 'File size cannot exceed 100KB!' });
                }

                /* VALIDATION PASSED */
                return true;
            }
            return this.createError({ message: 'Invalid value type!' });
        }),
    empCertDocId: Yup.mixed()
        .required('Required!')
        .test('fileType', 'Only PDF files are allowed!', function (value) {
            /* Access test context via 'this  */
            if (typeof value === 'string') {
                return !!value.trim() || this.createError({ message: 'Field cannot be empty!' });
            } else if (value instanceof File) {

                const validMimeType = value.type?.toLowerCase() === FILE_TYPES.PDF.toLowerCase();
                const validExtension = value.name.toLowerCase().endsWith(FILE_EXTENSION_TYPES.PDF.toLowerCase());

                /* VALID MEME TYPE */
                if (!validMimeType || !validExtension) {
                    return this.createError({ message: 'Only PDF files are allowed!' });
                }

                /* MINIMU SIZE */
                if (value.size < PDF_FILE_SIZE_VALIDATIONS.MINIMUM_SIZE) {
                    return this.createError({ message: 'File size must be at least 30KB!' });
                }

                /* MAXIMUM SIZE */
                if (value.size > PDF_FILE_SIZE_VALIDATIONS.MAXIMUM_SIZE) {
                    return this.createError({ message: 'File size cannot exceed 100KB!' });
                }

                /* VALIDATION PASSED */
                return true;
            }
            return this.createError({ message: 'Invalid value type!' });
        }),
    logo: Yup.mixed()
        .required('Required!')
        .test('fileType', 'Only Images are allowed!', function (value) {
            /* Access test context via 'this  */
            if (typeof value === 'string') {
                return !!value.trim() || this.createError({ message: 'Field cannot be empty!' });
            } else if (value instanceof File) {

                const validMimeType = value.type?.toLowerCase() === FILE_TYPES.JPEG.toLowerCase() || value.type?.toLowerCase() === FILE_TYPES.PNG.toLowerCase() || value.type?.toLowerCase() === FILE_TYPES.JPG.toLowerCase();
                const validExtension = value.name.toLowerCase().endsWith(FILE_EXTENSION_TYPES.JPEG.toLowerCase()) || value.name.toLowerCase().endsWith(FILE_EXTENSION_TYPES.JPG.toLowerCase()) || value.name.toLowerCase().endsWith(FILE_EXTENSION_TYPES.PNG.toLowerCase());

                /* VALID MEME TYPE */
                if (!validMimeType || !validExtension) {
                    return this.createError({ message: 'Only Images files are allowed!' });
                }

                /* MINIMU SIZE */
                if (value.size < IMG_FILE_SIZE_VALIDATIONS.MINIMUM_SIZE) {
                    return this.createError({ message: 'Image size must be at least 10KB!' });
                }

                /* MAXIMUM SIZE */
                if (value.size > IMG_FILE_SIZE_VALIDATIONS.MAXIMUM_SIZE) {
                    return this.createError({ message: 'Image size cannot exceed 100KB!' });
                }

                /* VALIDATION PASSED */
                return true;
            }
            return this.createError({ message: 'Invalid value type!' });
        }),
});

/* BUSINESS CONTACT PERSON  */
export const businessContactPersonValSchema = Yup.object({
    firstName: Yup.string()
        .required('Required!')
        .min(3, 'Must be at least 3 characters')
        .max(50, 'Must be 50 characters or less')
        .matches(noSpecialCharacterAllowedRegex, 'Special characters are not allowed'),
    lastName: Yup.string()
        .required('Required!')
        .min(3, 'Must be at least 3 characters')
        .max(50, 'Must be 50 characters or less')
        .matches(noSpecialCharacterAllowedRegex, 'Special characters are not allowed'),
    designation: Yup
        .number()
        .required('Required!')
        .integer('Please Select Designation From Dropdown!')
        .min(1, 'Please Select Designation From Dropdown!'),
    department: Yup.string()
        .required('Required!')
        .min(2, 'Must be at least 2 characters')
        .max(50, 'Must be 50 characters or less')
        .matches(noSpecialCharacterAllowedRegex, 'Special characters are not allowed'),
    email: Yup
        .string()
        .matches(validEmailRegex, 'Invalid email format')
        .required('Required!'),
    mobile: Yup
        .string()
        .required('Required')
        .matches(noSpaceAllowedRegex, 'No spaces are allowed')
        .matches(onlyNoRegex, 'Only numbers are allowed')
        .matches(validIndianMobileNoRegex, 'Enter Valid Indian Mobile No.')
        .length(10, 'Must Contain Exactly 10 Digits'),
    idProofType: Yup
        .string()
        .required('Required!'),
    idProofNo: Yup.string()
        .required('Required!')
        .test('idProofNo', function (value) {
            const { idProofType } = this.parent;
            let errorMessage = '';

            switch (idProofType) {
                case ID_TYPES.AADHARCARD:
                    if (!aadharcardRegex.test(value)) {
                        errorMessage = 'Invalid Aadhar: 12 digits required, no spaces and special characters allowed.';
                    }
                    break;
                case ID_TYPES.PANCARD:
                    if (!pancardRegex.test(value)) {
                        errorMessage = 'Invalid PAN: ABCDE1234F format required, no spaces or special characters allowed.';
                    }
                    break;
                case ID_TYPES.PASSPORT:
                    if (!passportRegex.test(value)) {
                        errorMessage = 'Invalid Passport: 8-9 characters required, no spaces or special characters allowed.';
                    }
                    break;
                default:
                    errorMessage = 'Invalid ID Proof: no spaces or special characters allowed.';
                    break;
            }
            return errorMessage ? this.createError({ message: errorMessage }) : true;
        }),
    idProofDocId: Yup.mixed()
        .required('Required!')
        .test('fileType', 'Only PDF files are allowed!', function (value) {
            /* Access test context via 'this  */
            if (typeof value === 'string') {
                return !!value.trim() || this.createError({ message: 'Field cannot be empty!' });
            } else if (value instanceof File) {

                const validMimeType = value.type?.toLowerCase() === FILE_TYPES.PDF.toLowerCase();
                const validExtension = value.name.toLowerCase().endsWith(FILE_EXTENSION_TYPES.PDF.toLowerCase());

                /* VALID MEME TYPE */
                if (!validMimeType || !validExtension) {
                    return this.createError({ message: 'Only PDF files are allowed!' });
                }

                /* MINIMU SIZE */
                if (value.size < PDF_FILE_SIZE_VALIDATIONS.MINIMUM_SIZE) {
                    return this.createError({ message: 'File size must be at least 30KB!' });
                }

                /* MAXIMUM SIZE */
                if (value.size > PDF_FILE_SIZE_VALIDATIONS.MAXIMUM_SIZE) {
                    return this.createError({ message: 'File size cannot exceed 100KB!' });
                }

                /* VALIDATION PASSED */
                return true;
            }
            return this.createError({ message: 'Invalid value type!' });
        }),
    usertype: Yup
        .string()
        .required('Required!'),
});

/* ADD IP ADDRESS */
export const addIPAddressValSchema = Yup.object({
    ipAddress: Yup.string()
        .required('Required!')
        .matches(ipRegex, 'Invalid IP address format'),
})

export const reqJourneyValSchema = Yup.object({
    name: Yup.string()
        .required('Required!')
        .min(3, 'Must be at least 3 characters')
        .max(50, 'Must be 50 characters or less')
        .matches(noSpecialCharacterAllowedRegex, 'Special characters are not allowed'),

    fileDoc: Yup.mixed()
        .required('Required!')
        .test('fileType', 'Only PDF files are allowed!', function (value) {
            /* Access test context via 'this  */
            if (typeof value === 'string') {
                return !!value.trim() || this.createError({ message: 'Field cannot be empty!' });
            } else if (value instanceof File) {

                const validMimeType = value.type?.toLowerCase() === FILE_TYPES.PDF.toLowerCase();
                const validExtension = value.name.toLowerCase().endsWith(FILE_EXTENSION_TYPES.PDF.toLowerCase());

                /* VALID MEME TYPE */
                if (!validMimeType || !validExtension) {
                    return this.createError({ message: 'Only PDF files are allowed!' });
                }

                /* MINIMU SIZE */
                if (value.size < PDF_FILE_SIZE_VALIDATIONS.MINIMUM_SIZE) {
                    return this.createError({ message: 'File size must be at least 30KB!' });
                }

                /* MAXIMUM SIZE */
                if (value.size > PDF_FILE_SIZE_VALIDATIONS.MAXIMUM_SIZE) {
                    return this.createError({ message: 'File size cannot exceed 100KB!' });
                }

                /* VALIDATION PASSED */
                return true;
            }
            return this.createError({ message: 'Invalid value type!' });
        }),
});

export const postLegalAgreementValSchema = Yup.object({
    comment: Yup.string()
        .required('Required!')
        .min(3, 'Must be at least 3 characters')
        .max(250, 'Must be 250 characters or less'),

    aggrementDoc: Yup.mixed()
        .required('Required!')
        // .nullable()
        .test('fileType', 'Only PDF files are allowed!', function (value) {
            /* Access test context via 'this' */
            // if (value === null || value === undefined) {
            //     // If there's no value, validation passes
            //     return true;
            // }

            if (typeof value === 'string') {
                /* IF IT IS STRING THEN CHECK FOR EMPTY */
                return !!value.trim() || this.createError({ message: 'Field cannot be empty!' });
            } else if (value instanceof File) {
                const validMimeType = value.type?.toLowerCase() === FILE_TYPES.PDF.toLowerCase();
                const validExtension = value.name.toLowerCase().endsWith(FILE_EXTENSION_TYPES.PDF.toLowerCase());

                /* VALID MIME TYPE */
                if (!validMimeType || !validExtension) {
                    return this.createError({ message: 'Only PDF files are allowed!' });
                }

                /* MINIMUM SIZE */
                if (value.size < PDF_FILE_SIZE_VALIDATIONS.MINIMUM_SIZE) {
                    return this.createError({ message: 'File size must be at least 30KB!' });
                }

                /* MAXIMUM SIZE */
                if (value.size > PDF_FILE_SIZE_VALIDATIONS.MAXIMUM_SIZE) {
                    return this.createError({ message: 'File size cannot exceed 100KB!' });
                }

                /* VALIDATION PASSED */
                return true;
            }
            return this.createError({ message: 'Invalid value type!' });
        }),
});
import { ApiPackagesIcon, NextIcon, PreviousIcon } from "../../assets";
import { API_PACKAGES_TABS, API_PAGES } from "../../data";
import AllSubscriptions from "../all-subscriptions/AllSubscriptions";

interface IProps {
    handleNextStep: () => void;
    handlePreviousStep: () => void;
}

const ApiPackages = (props: IProps) => {
    const { handleNextStep, handlePreviousStep } = props;

    return (
        <div className="panel panel-primary setup-content" id="step-6">
            <div className="onboarding-form">
                <div className="company-details-form common-p-20 bg-white-border">
                    <div className="title-box">
                        <div className="icon-box">
                            <img src={ApiPackagesIcon} alt="api-packages" />
                        </div>
                        <h4>API Results</h4>
                    </div>
                    <div className="api-packages-box-section">
                        <div className="inner-tab-box" id="ChildVerticalTab_1">
                            <div className="resp-tabs-container ver_1">
                                <AllSubscriptions tab={API_PACKAGES_TABS?.ALL_SUBSCRIPTIONS} page={API_PAGES.API_PACKAGES} />
                            </div>
                        </div>
                    </div>

                    <ul className="main-btn-group pager">
                        <li className="prev-btn-wrapper" onClick={handlePreviousStep}>
                            <button type="button" className="line-btn btn prevBtn">
                                <img src={PreviousIcon} alt="prev icon" className="prev-icon" />
                                Previous
                            </button>
                        </li>

                        <li className="next-btn-wrapper" onClick={handleNextStep}>
                            <button type="button" className="line-btn btn nextBtn">
                                Next
                                <img src={NextIcon} alt="next icon" className="next-icon" />
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ApiPackages;

import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';
import { apiManagerApi, authApi, newsAndHelpApi, ipWhitelistingApi, legalAgreementApi, onboardingApi } from '../../api';
import { authSlice, toastSlice } from '../slices';


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
};

const rootReducer = combineReducers({
    [authApi.reducerPath]: authApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    [ipWhitelistingApi.reducerPath]: ipWhitelistingApi.reducer,
    [apiManagerApi.reducerPath]: apiManagerApi.reducer,
    [legalAgreementApi.reducerPath]: legalAgreementApi.reducer,
    [newsAndHelpApi.reducerPath]: newsAndHelpApi.reducer,
    auth: authSlice.reducer,
    toast: toastSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;

export type AppState = ReturnType<typeof rootReducer>;
import { Suspense } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { LoadingFallback } from "./components";
import RouteWrapper from './routes/RouteWrapper';
import "./App.css";

const App = () => {
  const persistor = persistStore(store);
  return (
    <Suspense fallback={<LoadingFallback />}>
        <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <RouteWrapper />
            <ToastContainer />
          </BrowserRouter>
      </PersistGate>
      </Provider>
    </Suspense>
  )
}


// const App = () => {
//   return ( 
//<>
//
//  <RouteWrapper />
//  <ToastContainer />
//</>

//   )
// }

export default App

import { configureStore, Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { apiManagerApi, authApi, newsAndHelpApi, ipWhitelistingApi, legalAgreementApi, onboardingApi } from '../api';
import { logout } from './slices';
import { AppState } from './reducer';
import persistedReducer from './reducer';

/* GLOBAL ERROR DISPATCHER */
const rtkQueryErrorLogger: Middleware<{}, AppState> = (store) => (next) => (action: any) => {
  const dispatch = store?.dispatch;

  if (isRejectedWithValue(action)) {
    if (action && action?.payload) {
      const status = action?.payload?.status;
      console.error('rtkQueryErrorLogger', action);
      /* LOG OUT IF ACCESS IS 401 (UNAUTHORIZED) or 403 (FORBIDDEN) */
      if (status === 401 || status === 403) {
        dispatch(logout());
        // dispatch(showToast({ message: "Session Expired! Please login again.", type: 'error' }));
        return;
      }
    }
  }

  return next(action);
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(
        authApi.middleware,
        onboardingApi.middleware,
        ipWhitelistingApi.middleware,
        apiManagerApi.middleware,
        legalAgreementApi.middleware,
        newsAndHelpApi.middleware,
        rtkQueryErrorLogger
      ),
});

setupListeners(store?.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

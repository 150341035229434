import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IP_STATUS_OPTIONS, STATUS_TYPES } from "../../data";
import { statusLabelBG } from "../../utils";
import { usePutRbiIPMutation } from "../../api";
import { showToast } from "../../redux/slices";
import { capitalizeFirstCharacter, formatDateToDDMMMYYYY } from "../../helpers";
import { RootState } from "../../redux/store";
import CustomSelect from "../custom-select/CustomSelect";
import NoRecordsFound from "../no-records-found/NoRecordsFound";
import Loader from "../loader/Loader";

interface IProps {
    ips: IIP[];
    filter: string;
    isLoading: boolean;
}

interface IIP {
    status: string;
    comment?: string;
    port?: string;
    url?: string;
    ipOwn: string;
    ip: string;
    id: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
}

const IPWhitelistingTable = ({ ips, filter, isLoading }: IProps) => {
    const dispatch = useDispatch();
    const userEmail = useSelector((state: RootState) => state.auth.user?.email);
    const [localIps, setLocalIps] = useState<IIP[]>(ips);
    const [putRbiIpStatus, { isLoading: isPutRbiStatusLoading }] = usePutRbiIPMutation();
    const RbiHeaders = ["No.", "IP Address", "Status", "Comment", "Port", "URL", "Updated By", "Date"];
    const LenderHeaders = ["No.", "IP Address", "Status", "Updated By", "Date"];
    /* DETERMINE HEADERS BASED ON FILTER */
    const headers = filter === "L" ? LenderHeaders : RbiHeaders;

    /* UPDATE RBI DEFAULT STATUS */
    useEffect(() => {
        if (ips?.length <= 0) return;
        setLocalIps(ips);
    }, [ips, filter]);

    const handleSubmitOnChange = (status: string, id: string) => {
        const currentIp = localIps.find((ip) => ip.id === id);

        if (!currentIp) {
            dispatch(showToast({ message: "IP not found!", type: 'error' }));
            return;
        }

        const updatedIp: IIP = {
            ...currentIp,
            status,
            lastModifiedBy: userEmail,
            lastModifiedDate: new Date()?.toISOString(),
        };

        setLocalIps((prevIps) => prevIps.map((ip) => (ip.id === id ? updatedIp : ip)));

        const payload = { status, id };

        putRbiIpStatus(payload)
            .unwrap()
            .then((res) => dispatch(showToast({ message: res?.message || "Success!", type: 'success' })))
            .catch((error) => {
                setLocalIps((prevIps) => prevIps.map((ip) => (ip.id === id ? currentIp : ip)));
                dispatch(showToast({ message: error?.data?.message || "Failed", type: 'error' }));
            });
    };

    return (
        <table className="table table-responsive common-table">
            <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {isLoading ? (
                    <tr>
                        <td colSpan={headers?.length}>
                            <Loader height="100px" />
                        </td>
                    </tr>
                ) : localIps?.length > 0 ? (
                    localIps
                        .filter((ip: IIP) => ip.ipOwn === filter)
                        .map((ip: IIP, index) => (
                            <tr key={ip.id}>
                                <td>{index + 1}</td>
                                <td>{ip?.ip}</td>
                                <td className="status-bg-box">
                                    {filter === "R" ? (
                                        <CustomSelect
                                            id="status"
                                            name="status"
                                            className=""
                                            parentClassName="select-wrapper"
                                            ariaLabel="Status selection"
                                            options={IP_STATUS_OPTIONS}
                                            value={ip?.status || STATUS_TYPES.SUBMITTED}
                                            onChange={(e) => handleSubmitOnChange(e.target.value, ip.id)}
                                            onBlur={() => { }}
                                            disabled={isPutRbiStatusLoading}
                                        />
                                    ) : (
                                        <span className={`${statusLabelBG(ip.status)}`}>
                                            {capitalizeFirstCharacter(ip.status)}
                                        </span>
                                    )}
                                </td>
                                {filter === "R" && (
                                    <>
                                        <td>{ip?.comment || "-"}</td>
                                        <td>{ip?.port || "-"}</td>
                                        <td>{ip?.url || "-"}</td>
                                    </>
                                )}
                                <td>{ip?.lastModifiedBy}</td>
                                <td>{formatDateToDDMMMYYYY(ip?.lastModifiedDate)}</td>
                            </tr>
                        ))
                ) : (
                    <tr>
                        <td colSpan={headers?.length}>
                            <NoRecordsFound height="100px" message="No IPs Available!" />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default IPWhitelistingTable;
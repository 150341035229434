import Select from "react-select";
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetAllApiManagerQuery, useLazyGetCategoriesQuery, useLazyGetCompanyDetailsQuery, useLazyGetSubscribedApiQuery, usePostSubscribeApiMutation } from '../../api';
import { RootState } from '../../redux/store';
import { convertBase64ToDataUrl } from '../../helpers';
import { showToast } from '../../redux/slices';
import { API_PAGES, REQUESTED_API_MESSAGE, STATUS_TYPES } from '../../data';
import { ImagePlaceholder, SearchIcon } from '../../assets';
import { ViewApiDetailsModal } from "../modals";
import Loader from '../loader/Loader';
import NoRecordsFound from '../no-records-found/NoRecordsFound';

interface IProps {
    tab: string;
    page: string;
}

interface ApiCardProps {
    logo: string;
    name: string;
    description: string;
    provider: string;
    id: string;
}

interface ApiCategory {
    createdBy: string;
    creationDate: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
    id: number;
    name: string;
}

interface BodyItem {
    createdBy: string;
    creationDate: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
    id: string;
    name: string;
    description: string;
    version: string;
    context: string;
    provider: string;
    tags: string;
    logo: string | null;
    apiCategories: ApiCategory[];
}

const AllSubscriptions = ({ tab, page }: IProps) => {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState("");
    const [isViewDetailsModalOpen, setIsViewDetailsModalOpen] = useState<boolean>(false);
    const [apiId, setApiId] = useState<string | null>(null);
    const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
    const [getAllApiManager, { data, isLoading }] = useLazyGetAllApiManagerQuery();
    const [postSubscribeApi, { isLoading: isPostSubsribeApiLoading }] = usePostSubscribeApiMutation();
    const [getAllCategories] = useLazyGetCategoriesQuery();
    const [getSubscribedApis, { data: subscribedApis }] = useLazyGetSubscribedApiQuery();
    const [allCategories, setAllCategories] = useState<any[]>([]);
    const [getCompanyDetails] = useLazyGetCompanyDetailsQuery();
    const bankId = useSelector((state: RootState) => state.auth.bank?.id);

    const openViewDetailsModal = (id: string) => {
        setApiId(id)
        setIsViewDetailsModalOpen(true);
    };
    const closeViewDetailsModal = () => {
        setIsViewDetailsModalOpen(false)
        setApiId(null);
    };

    /* HANDLE SEARCH */
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value);

    /* FILTER SEARCHED DATA */
    const isCategorySelected = (api: BodyItem, selectedCategories: any[]) => (selectedCategories.length === 0 || api?.apiCategories?.some((category: ApiCategory) => selectedCategories?.some((selected: any) => selected?.value === category?.id)));

    /* SEARCH */
    const isSearchMatch = (api: BodyItem, searchQuery: string) => (api?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) || api?.description?.toLowerCase()?.includes(searchQuery?.toLowerCase()) || api?.provider?.toLowerCase()?.includes(searchQuery?.toLowerCase()));

    const filteredData = data?.body.filter((api: BodyItem) => isCategorySelected(api, selectedCategories) && isSearchMatch(api, searchQuery));

    const handleCategoryChange = (selectedOptions: any) => setSelectedCategories(selectedOptions || []);

    /* CHECK IF API IS SUBSCRIBED (STATUS == APPROVED) */
    const isSubscribed = (apiId: string) => subscribedApis?.body?.some((subscribedApi: { api: ApiCardProps, status: string }) => subscribedApi?.api?.id === apiId && subscribedApi.status === STATUS_TYPES.APPROVED);

    /* CHECK IF API IS REQUESTED (STATUS == PENDING || SUBSCRIBED) */
    const isRequested = (apiId: string) => subscribedApis?.body?.some((subscribedApi: { api: ApiCardProps, status: string }) => subscribedApi?.api?.id === apiId);

    const handleCloseModal = () => closeViewDetailsModal();

    const handleSubscribe = (id: string) => {
        const payload = { id, bankId };
        postSubscribeApi(payload)
            .unwrap()
            .then((res) => dispatch(showToast({ message: page === API_PAGES.API_PACKAGES ? (res?.message || "Success") : REQUESTED_API_MESSAGE, type: 'success' })))
            .catch((error) => console.log(error));
    };


    useEffect(() => {
        getAllCategories({})
            .unwrap()
            .then((res) => {
                const cats = res?.body;
                if (cats?.length > 0) {
                    const formattedCategories = cats.map((cat: ApiCategory) => ({
                        value: cat?.id,
                        label: cat?.name
                    }));
                    setAllCategories(formattedCategories);
                }
            })
            .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Internal Server Error! Please try again later.', type: 'error' })));

        getAllApiManager({})
            .unwrap()
            .catch((error) => dispatch(showToast({ message: error?.data?.message ?? "Error!", type: 'error' })));

        if (bankId) {
            getSubscribedApis(bankId).unwrap().catch((error) => dispatch(showToast({ message: error?.data?.message ?? "Error!", type: 'error' })));
        }
    }, [dispatch, getAllApiManager, getAllCategories, getSubscribedApis, bankId, tab]);

    useEffect(() => {
        /* COMPANY DETAILS */
        getCompanyDetails(bankId).unwrap();
    }, [bankId, getCompanyDetails])

    return (
        <div>
            <div className="main-search-box common-flex">
                <div className="top-search-box common-flex">
                    <div className="form-group">
                        <img src={SearchIcon} alt="search" />
                        <input
                            type="search"
                            className="form-control"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
                <div className="right-btn-box">
                    <div className="form-group">
                        <Select
                            isMulti
                            options={allCategories}
                            onChange={handleCategoryChange}
                            placeholder="Select Categories"
                            value={selectedCategories}
                            isClearable={true}
                            closeMenuOnSelect={false}
                            styles={{
                                control: (styles) => ({
                                    ...styles,
                                    backgroundColor: "#f5f7fc",
                                    borderWidth: 1,
                                    padding: 5,
                                    borderRadius: 5,
                                    minHeight: '40px',
                                    minWidth: '400px',
                                    maxWidth: '800px',
                                }),
                            }}
                        />
                    </div>
                </div>
            </div>
            {isLoading ? (
                <Loader height="400px" />
            ) : (
                filteredData?.length <= 0 ? (
                    <NoRecordsFound message="No API Manager Found!" height="400px" />
                ) : (
                    <section className="main-api-cards-section">
                        {filteredData?.map((api: ApiCardProps, index: number) => (
                            <div className="common-card-box" key={index}>
                                <div className="logo-box">
                                    <img src={api?.logo ? convertBase64ToDataUrl(api?.logo) : ImagePlaceholder} alt={`${api?.name}-logo`} />
                                </div>
                                <h4>{api?.name}</h4>
                                <p>{api?.description}</p>
                                <div className="tags-box">
                                    {api?.provider && api?.provider?.trim() !== '' && api?.provider?.split(',')?.length > 0 && (
                                        api?.provider.split(',').map((tag, index) => (
                                            <button type="button" className="tagbox" key={index}>
                                                <span>{tag?.trim()}</span>
                                            </button>
                                        ))
                                    )}
                                </div>
                                <div className="card-btn-box">
                                    {isSubscribed(api?.id)
                                        ? (<button type="button" className="btn light-btn gray-btn" onClick={() => dispatch(showToast({ message: "You've already subscribed to this API.", type: 'error' }))}>
                                            Subscribed
                                        </button>) :
                                        isRequested(api?.id)
                                            ? (<button type="button" className="btn light-btn gray-btn" onClick={() => dispatch(showToast({ message: "You've already requested this API.", type: 'error' }))}>
                                                Requested
                                            </button>)
                                            : (<button type="button" disabled={isPostSubsribeApiLoading} className="btn light-btn green-btn" onClick={() => handleSubscribe(api?.id)} >
                                                Request
                                            </button>
                                            )}
                                    <button type="button" className="btn light-btn" title="View Details" onClick={() => openViewDetailsModal(api?.id)}>
                                        <span>View Details</span>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </section>
                )
            )}

            <ViewApiDetailsModal
                open={isViewDetailsModalOpen}
                closeModal={handleCloseModal}
                apiId={apiId}
            />
        </div>
    );
};

export default AllSubscriptions;
import { useEffect, useState } from "react";
import { ActivityStatusIcon } from "../../assets";
import { useLazyGetOnboardingProgressQuery } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../redux/slices";
import { RootState } from "../../redux/store";
import Loader from "../loader/Loader";

const OnboardingProgress = () => {
    const dispatch = useDispatch();
    const [getOnboardingProgress, { isLoading }] = useLazyGetOnboardingProgressQuery();
    const bankId = useSelector((state: RootState) => state.auth.bank?.id);
    const [progressData, setProgressData] = useState<{ [key: string]: string }>({ OF: "false", LJ: "false", LA: "false", FC: "false" });

    useEffect(() => {
        if (bankId) {
            getOnboardingProgress(bankId)
                .unwrap()
                .then((response) => {
                    setProgressData(prevData => ({ ...prevData, ...response }));
                })
                .catch(() => {
                    dispatch(showToast({ message: "Failed to get accurate onboarding progress data!", type: 'error' }))
                    setProgressData(prevData => ({ ...prevData, OF: "false", LJ: "false", LA: "false", FC: "false" }));
                });
        }
    }, [bankId, dispatch, getOnboardingProgress]);

    const steps = [
        { key: 'OF', label: 'Onboarding Form' },
        { key: 'LJ', label: 'Lending Journey' },
        { key: 'LA', label: 'Legal Agreement' },
        { key: 'FC', label: 'Final Completion' },
    ];

    const getStatusClass = (status: string) => status === "true" ? "active" : "current";

    return (
        <>
            <div className="title-box">
                <div className="icon-box">
                    <img src={ActivityStatusIcon} alt="icon" />
                </div>
                <h4>Onboarding Progress</h4>
            </div>
            <ul className="multi-steps">
                {isLoading
                    ? (<Loader height="100px" />)
                    : (steps?.map(({ key, label }) => (
                        <li key={key} className={`status ${getStatusClass(progressData[key])}`}>
                            {label}
                        </li>))
                    )}
            </ul>
        </>
    );
};

export default OnboardingProgress;
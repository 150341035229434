import { useEffect } from 'react';
import { HelpIcon } from '../../assets';
import { useLazyGetHelpQuery } from '../../api/newsfeed-and-help';
import { showToast } from '../../redux/slices';
import { useDispatch } from 'react-redux';
import Loader from '../loader/Loader';
import NoRecordsFound from '../no-records-found/NoRecordsFound';

interface IHelp {
    title: string
    description: string
    url?: string
    id?: number
}

const HelpBox = () => {
    const dispatch = useDispatch();
    const [getAllHelps, { isLoading, data }] = useLazyGetHelpQuery();

    useEffect(() => {

        /* GET HELPS */
        getAllHelps({})
            .unwrap()
            .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Error!', type: 'error' })));
    }, [dispatch, getAllHelps])

    return (
        <div className="help-section common-p-20 bg-white-border">
            <div className="title-box">
                <div className="icon-box">
                    <img src={HelpIcon} alt="icon" />
                </div>
                <h4>Help</h4>
            </div>
            <div className="main-box">
                {isLoading
                    ? <Loader />
                    : data?.body?.length <= 0
                        ? <NoRecordsFound message='No Help Available' />
                        : data?.body?.map((help: IHelp) => (
                            <div className="help-common-box" key={help?.id}>
                                <h5>{help?.title}</h5>
                                <p>{help?.description}</p>
                                {help?.url && help?.url?.trim() !== "" && <a href={help?.url} className="btn light-btn  mt-2" rel="noreferrer" title="Read More" target='_blank'>Read More</a>}
                            </div>
                        ))
                }
            </div>
        </div>
    )
}

export default HelpBox;

import Modal from "react-responsive-modal";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { showToast } from "../../redux/slices";
import { useLazyGetSingleApiManagerQuery } from "../../api";
import { convertBase64ToDataUrl } from "../../helpers";
import { ImagePlaceholder } from "../../assets";
import Loader from "../loader/Loader";

interface IProps {
    open: boolean;
    closeModal: () => void;
    apiId: string | null;
}

interface IApiCategories {
    id: number;
    name: string;
}

interface DetailFieldProps {
    label: string;
    value: string | number | undefined;
}

const ViewApiDetailsModal = (props: IProps) => {
    const dispatch = useDispatch();
    const { open, closeModal, apiId } = props;
    const minWidth = "60vw";
    const [getSingleApiManager, { isLoading, data }] = useLazyGetSingleApiManagerQuery();

    const handleCancel = () => {
        closeModal();
    };

    useEffect(() => {
        if (open) {
            if (!apiId) {
                dispatch(showToast({ message: "Invalid API!", type: 'error' }));
                return;
            }

            getSingleApiManager(apiId)
                .unwrap()
                .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Internal Server Error! Please try again later.', type: 'error' })));
        }

    }, [dispatch, apiId, getSingleApiManager, open]);

    const DetailField = ({ label, value }: DetailFieldProps) => (
        <div className="col-6">
            <div className="form-group">
                <label className="form-label">
                    {label}
                </label>
                <input
                    className="form-control"
                    type="text"
                    value={value || ''}
                    disabled
                />
            </div>
        </div>
    );

    return (
        <Modal
            center
            open={open}
            onClose={() => handleCancel()}
            onEscKeyDown={() => handleCancel()}
            showCloseIcon
            styles={{ modal: { minWidth } }}
            classNames={{ modal: "custom-modal" }}
        >
            {/* HEADER */}
            <div className="title-box">
                <h5>View API Details</h5>
            </div>

            {/* BODY */}
            <div className=" ">
                {isLoading ? (<Loader />) : (
                    <div className="row">
                        <DetailField label="Name" value={data?.body?.name} />
                        {/* <DetailField label="Version" value={data?.body?.version} /> */}
                        {/* <DetailField label="Context" value={data?.body?.context} /> */}
                        {/* <DetailField label="Provider" value={data?.body?.provider} /> */}
                        <DetailField label="API Categories" value={data?.body?.apiCategories?.map((cat: IApiCategories) => cat?.name).join(', ') || ''} />
                        {/* <DetailField label="Tags" value={data?.body?.tags} /> */}
                        <div className="col-4">
                            <div className="form-group">
                                <label className="form-label">
                                    Logo
                                </label>
                                <div className="d-flex flex-column">
                                    <img src={data?.body?.logo ? convertBase64ToDataUrl(data?.body?.logo) : ImagePlaceholder} alt="api-logo-image" height={80} width={120} />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="form-label">
                                    Description
                                </label>
                                <textarea
                                    className="form-control"
                                    rows={3}
                                    value={data?.body?.description}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="form-label">
                                    Provider
                                </label>
                                <textarea
                                    className="form-control"
                                    rows={3}
                                    value={data?.body?.provider}
                                    disabled
                                />
                            </div>
                        </div>

                        {/* <DetailField label="Logo" value={data?.body?.logo } /> */}
                        {/* <div className="col-4">
                            <div className="form-group">
                                <label className="form-label">
                                    API Categories
                                </label>
                                <textarea
                                    className="form-control"
                                    rows={3}
                                    value={data?.body?.apiCategories?.map((cat: IApiCategories) => cat?.name).join(', ') || ''}
                                    disabled
                                />
                            </div>
                        </div> */}
                    </div>
                )}
            </div>

            {/* FOOTER */}
            <div className="modal-btn-footer">
                <button type="button" className="line-btn btn" onClick={() => handleCancel()}>Close</button>
            </div>

        </Modal >
    );
};

export default ViewApiDetailsModal;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetCompanyDetailsQuery, useLazyGetContactPersonsQuery, useLazyGetSavedLendingJourneysQuery } from "../../api";
import { PreviousIcon, ReviewApplicationIcon } from "../../assets";
import { RootState } from "../../redux/store";
import { FinalSubmitModal } from "../modals";
import { showToast } from "../../redux/slices";
import { CONTACT_PERSON_TYPES, FINAL_SUBMISSION_NOTE, STATUS_TYPES } from "../../data";
import { capitalizeFirstCharacter, formatDateToDDMMMYYYY } from "../../helpers";
import CompanyDetailsSection from "./CompanyDetailsSection";
import DesignatedContactPersonSection from "./DesignatedContactPersonSection";
import LendingJourneySection from "./LendingJourneySection";

interface IProps {
    handlePreviousStep?: () => void;
}

const ReviewApplication = (props: IProps) => {
    const dispatch = useDispatch();
    const bank = useSelector((state: RootState) => state.auth.bank);
    const [open, setOpen] = useState<boolean>(false);
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);
    const { handlePreviousStep } = props;
    const [getSavedJourneys, { data: savedJourneys, isLoading: isSavedJourneyIsLoading }] = useLazyGetSavedLendingJourneysQuery();
    const [getCompanyDetails, { data: companyDetails, isLoading: isCompanyDetailsLoading }] = useLazyGetCompanyDetailsQuery();
    const [getContactPerson, { data: contactPersons, isLoading: isContactPersonLoading }] = useLazyGetContactPersonsQuery();
    const isFormApproved = bank?.status === STATUS_TYPES.APPROVED;
    const isFormReviewed = bank?.status === STATUS_TYPES.REVIEWED;
    const isFormSubmitted = bank?.status === STATUS_TYPES.SUBMITTED;
    const formStatus = bank?.markCompleted === true
        ? `Your form has been Completed on ${formatDateToDDMMMYYYY(companyDetails?.body?.lastModifiedDate)}`
        : `Your form has been ${capitalizeFirstCharacter(companyDetails?.body?.status)} on ${formatDateToDDMMMYYYY(companyDetails?.body?.lastModifiedDate)}`;

    const handleSubmit = () => {
        if (!isTermsChecked) {
            dispatch(showToast({ message: "Pls read and check terms and conditions.", type: 'error' }));
            return;
        }
        openModal();
    }

    /* FETCH DETAILS */
    useEffect(() => {
        if (!bank?.id?.trim()) {
            return;
        }

        /* COMPANY DETAILS */
        getCompanyDetails(bank?.id)
            .unwrap()
            .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Internal Server Error! Please try again later.', type: 'error' })));

        /* DESIGNATED CONTACT PERSON */
        getContactPerson(bank?.id)
            .unwrap()
            .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Internal Server Error! Please try again later.', type: 'error' })));

        /* SAVED JOURNEYS */
        getSavedJourneys(bank?.id)
            .unwrap()
            .catch((error) => dispatch(showToast({ message: error?.data?.message || "Failed", type: 'error' })));
    }, [getCompanyDetails, bank?.id, dispatch, getContactPerson, getSavedJourneys]);

    return (
        <>
            <div className="panel panel-primary setup-content" id="step-7">
                <div className="onboarding-form">
                    <div className="company-details-form common-p-20 bg-white-border">
                        <div className="title-box">
                            <div className="icon-box">
                                <img src={ReviewApplicationIcon} alt="api-packages" />
                            </div>
                            <h4>Review Application</h4>
                            {(isFormReviewed || isFormSubmitted || isFormApproved) && <h4 className="orange-note-box">
                                {formStatus}
                            </h4>}
                        </div>

                        <div className="review-application-box">

                            {/* COMPANY DETAILS */}
                            <CompanyDetailsSection
                                companyDetails={companyDetails?.body}
                                isLoading={isCompanyDetailsLoading}
                            />

                            {/* DESIGNATED CONTACT PERSON */}
                            <DesignatedContactPersonSection
                                header={CONTACT_PERSON_TYPES.BUSINESS}
                                isLoading={isContactPersonLoading}
                                contactPersons={contactPersons?.body}
                            />

                            {/* DESIGNATED CONTACT PERSON */}
                            <DesignatedContactPersonSection
                                header={CONTACT_PERSON_TYPES.IT}
                                isLoading={isContactPersonLoading}
                                contactPersons={contactPersons?.body}
                            />

                            {/* DESIGNATED CONTACT PERSON (ESCLATION 1) */}
                            <DesignatedContactPersonSection
                                header={CONTACT_PERSON_TYPES.ESCALATION}
                                isLoading={isContactPersonLoading}
                                contactPersons={contactPersons?.body}
                            />

                            {/* LENDING JOURNEY */}
                            <LendingJourneySection
                                isLoading={isSavedJourneyIsLoading}
                                savedJourneys={savedJourneys?.body}
                            />

                        </div>

                        {/* SHOW ONLY WHEN STATUS IS NOT IN REVIEWED FORM OR APPROVED */}
                        {isFormReviewed || isFormApproved || isFormSubmitted
                            ? null
                            : (
                                <>
                                    {/* T&C */}
                                    <div className="terms-conditons">
                                        <input type="checkbox" onChange={(e) => setIsTermsChecked(e.target.checked)} className="form-check-input" />
                                        <p>{FINAL_SUBMISSION_NOTE}</p>
                                    </div>

                                    {/* FOOTER BUTTONS */}
                                    <ul className="main-btn-group pager">
                                        <button type="button" disabled={isFormApproved} onClick={() => handleSubmit()} className="common-btn btn">
                                            Submit
                                        </button>

                                        {/* PREV BUTTON */}
                                        <li className="prev-btn-wrapper" onClick={() => handlePreviousStep ? handlePreviousStep() : null}>
                                            <button type="button" className="line-btn btn prevBtn">
                                                <img src={PreviousIcon} alt="prev icon" className="prev-icon" />
                                                Previous
                                            </button>
                                        </li>
                                    </ul>
                                </>)}
                    </div>
                </div>
            </div>

            {/* FINAL SUBMIT MODAL */}
            <FinalSubmitModal
                open={open}
                closeModal={closeModal}
            />
        </>
    )
}

export default ReviewApplication;

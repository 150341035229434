import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINT } from './endpoints';
import baseQuery from '../baseQuery';

export const ipWhitelistingApi = createApi({
    reducerPath: 'ipWhitelistingApi',

    baseQuery,

    tagTypes: [
        "get_all_ips",
    ],

    endpoints: (builder) => ({

        /* GET ALL IPS */
        getAllIPs: builder.query({
            query: (bankId) => ({
                url: `${ENDPOINT.GET_ALL_IPS}/${bankId}`,
                method: "GET",
            }),
            providesTags: ["get_all_ips"]
        }),

        /* PUT RBI IP*/
        putRbiIP: builder.mutation({
            query: ({ id, status }) => {
                return ({
                    url: `${ENDPOINT.PUT_RBI_IP}/${id}?status=${status}`,
                    method: "PUT",
                })
            },
        }),

        /* POST LENDER IP */
        postLenderIP: builder.mutation({
            query: (data) => ({
                url: ENDPOINT.POST_LENDER_IP,
                body: data,
                method: "POST",
            }),
            invalidatesTags: ["get_all_ips"],
        }),
    }),
})

export default ipWhitelistingApi;

export const {

    useLazyGetAllIPsQuery,
    usePostLenderIPMutation,
    usePutRbiIPMutation,

} = ipWhitelistingApi;

import { useFormik } from 'formik';
import { addIPAddressValSchema } from '../../validations';
import { showToast } from '../../redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import { usePostLenderIPMutation } from '../../api';
import { RootState } from '../../redux/store';
import Modal from 'react-responsive-modal';

interface IProps {
    open: boolean;
    closeModal: () => void;
    minWidth?: string
}

const AddIPAddressModal = (props: IProps) => {
    const { open, closeModal, minWidth = "40vw" } = props;
    const dispatch = useDispatch();
    const [AddIPAddress, { isLoading }] = usePostLenderIPMutation();
    const bankId = useSelector((state: RootState) => state.auth.bank?.id);

    const formik = useFormik({
        initialValues: {
            ipAddress: '',
        },
        validationSchema: addIPAddressValSchema,
        onSubmit: values => {
            /* STATIC STATUS AND ipOwn */
            const payload = { ip: values?.ipAddress, ipOwn: "L", bankId }
            AddIPAddress(payload)
                .unwrap()
                .then((res) => {
                    formik.resetForm();
                    closeModal();
                    dispatch(showToast({ message: res?.message || "Success!", type: 'success' }))
                })
                .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Failed!', type: 'error' })));
        },
    });

    const handleCancel = () => {
        formik.resetForm();
        closeModal()
    };

    return (
        <Modal
            center
            open={open}
            onClose={() => handleCancel()}
            onEscKeyDown={() => handleCancel()}
            showCloseIcon
            styles={{ modal: { minWidth } }}
            classNames={{ modal: "custom-modal" }}
        >
            <form onSubmit={formik.handleSubmit}>

                {/* HEADER */}
                <div className="title-box">
                    <h5>Add IP Address</h5>
                </div>

                {/* BODY */}
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="form-label">IP Address</label>
                            <input
                                className='form-control'
                                type="text"
                                name="ipAddress"
                                placeholder="Enter IP Address"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.ipAddress}
                            />
                            {formik.touched.ipAddress && formik.errors.ipAddress ? (
                                <div className="error-msg">{formik.errors.ipAddress}</div>
                            ) : null}
                        </div>
                    </div>
                </div>

                {/* FOOTER */}
                <div className="main-btn-group pager">
                    <button type="button" className="line-btn btn" onClick={() => handleCancel()}>Cancel</button>
                    <button type="submit" disabled={isLoading} className="common-btn btn">
                        {isLoading && <div className="submit-button-loader" />}Save
                    </button>
                </div>

            </form>
        </Modal>
    );
};

export default AddIPAddressModal;

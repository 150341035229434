import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfileIcon, LogoutIcon, SidebarSettingsIcon, AvatarIcon } from '../../assets';
import { usePathname, useRouter } from '../../hooks';
import { logout } from '../../redux/slices';
import { RootState } from '../../redux/store';
import { PATHS, TITLES } from '../../data';
import { getNavItems } from '../../utils';

interface IProps {
    updateSidebarCollapse: () => void;
}

const Header = ({ updateSidebarCollapse }: IProps) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const pathname = usePathname();
    const { user, bank } = useSelector((state: RootState) => state.auth);
    const navItems = getNavItems(user.usertype, bank?.status);
    const [headerName, setHeaderName] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);

    /* THIS WILL SHOW HEADER TITLE AS PER PAGE */
    const headerTitles = useMemo(() => {
        return [...navItems, { path: PATHS.USER_PROFILE, title: TITLES[PATHS.USER_PROFILE] }].reduce<Record<string, string>>((titles, item) => {
            titles[item.path] = item.title;
            return titles;
        }, {});
    }, [navItems]);

    const toggleDropdown = () => setDropdownVisible(prev => !prev);

    const handleGoToProfile = () => {
        router.push(PATHS.USER_PROFILE);
        setDropdownVisible(false);
        toggleDropdown()
    };

    const handleLogout = () => {
        dispatch(logout());
        setDropdownVisible(false);
        toggleDropdown()
    }

    useEffect(() => {
        /* SET HEADER NAME AS PER PAGE */
        const newHeaderName = headerTitles[pathname] || "";
        setHeaderName(newHeaderName);
    }, [pathname, headerTitles]);

    return (
        <nav className="custom-nav">
            <div className="left-box">
                <div className="menu-box">
                    <button onClick={updateSidebarCollapse} type="button" className="menu-btn">
                        <img src={SidebarSettingsIcon} alt="icon" />
                    </button>
                </div>
                <h3>{headerName}</h3>
            </div>

            <div className="right-box">
                <ul>
                    {/* BANK LOGO & NAME */}
                    {bank?.logo?.startsWith('data:image/') && (
                        <li className="lender-logo">
                            <img src={bank?.logo} alt="bank-logo" />
                        </li>
                    )}

                    {/* NOTIFICATIONS */}
                    {/* <li className="icon-circle">
                        <a href="#/">
                            <span>
                                <img src={NotificationsIcon} alt="icon" />
                            </span>
                        </a>
                    </li> */}

                    {/* PROFILE */}
                    <li className={`white-bg arrow ${dropdownVisible ? 'active' : ''}`} onClick={toggleDropdown}>
                        <div className="dropbtn">
                            <span className="profile-img">
                                <img src={AvatarIcon} alt="user-profile" />
                            </span>
                        </div>
                        <p style={{ cursor: 'pointer' }}>
                            {user?.firstName} {user?.lastName}
                            <span>
                                {user?.designation?.name && bank?.bankName
                                    ? (user?.designation?.name?.trim() && bank?.bankName?.trim()
                                        ? `${user?.designation?.name?.trim()} | ${bank?.bankName?.trim()}`
                                        : user?.designation?.name?.trim() || bank?.bankName?.trim())
                                    : (user?.designation?.name?.trim() || bank?.bankName?.trim() || '')}
                            </span>
                        </p>
                        {dropdownVisible && (
                            <div className="dropdown">
                                <div id="myDropdown" className="dropdown-content show">
                                    <div className="dropdown-item" onClick={handleGoToProfile}>
                                        <img src={UserProfileIcon} alt="icon" />
                                        My Profile
                                    </div>
                                    <div className="dropdown-item" onClick={handleLogout}>
                                        <img src={LogoutIcon} alt="icon" />
                                        Log Out
                                    </div>
                                </div>
                            </div>
                        )}
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Header;

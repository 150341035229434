import { STATUS_TYPES } from "../data";

const statusLabelBG = (status: string | null | undefined): string => {
    const normalizedStatus = status?.toLowerCase() || "";

    switch (normalizedStatus) {

        case STATUS_TYPES.APPROVED:
            return "light-green-bg light-grren-text";

        case STATUS_TYPES.REJECTED:
            return "pink-bg pink-text";

        case STATUS_TYPES.PENDING:
            return "orange-bg orange-text";

        case STATUS_TYPES.WHITELISTED:
            return "light-green-bg light-grren-text";

        case STATUS_TYPES.BLACKLISTED:
            return "pink-bg pink-text";

        default:
            return "orange-bg orange-text";
    }
};

export default statusLabelBG;
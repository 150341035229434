import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { AuthLayout, DashboardLayout } from '../container';
import { PATHS, STATUS_TYPES, USER_TYPES } from '../data';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const DashboardPage = lazy(() => import('../pages/DashboardPage'));
const LandingJourneyPage = lazy(() => import('../pages/LendingJourneyPage'));
const IPWhitelistingPage = lazy(() => import('../pages/IPWhitelistingPage'));
const LegalAgreementPage = lazy(() => import('../pages/LegalAgreementPage'));
const LoginPage = lazy(() => import('../pages/LoginPage'));
const OnboardingFormPage = lazy(() => import('../pages/OnboardingFormPage'));
const ResetPasswordPage = lazy(() => import('../pages/ResetPasswordPage'));
const UserProfilePage = lazy(() => import('../pages/UserProfilePage'));
const ApiManagerPage = lazy(() => import('../pages/ApiManagerPage'));

const RouteWrapper = () => {
    const status = useSelector((state: RootState) => (state.auth.bank?.status));
    const userType = useSelector((state: RootState) => (state.auth.user?.usertype));

    const determineRedirectPath = () => {
        if (!userType || !status) {
            return PATHS.LOGIN;
        }
        switch (userType) {
            case USER_TYPES.LE:
                return PATHS.DASHBOARD;
            case USER_TYPES.IT:
                return status === STATUS_TYPES.APPROVED
                    ? PATHS.IP_WHITELISTING
                    : PATHS.LOGIN;
            default:
                return PATHS.LOGIN;
        }
    };

    const routes = useRoutes([
        {
            element: <DashboardLayout />,
            children: [
                /* COMMON ROUTES ACCESSIBLE BY BOTH */
                { path: PATHS.USER_PROFILE, element: <UserProfilePage /> },

                /* ROUTE ACCESSIBLE BY LE USERS WITH APPROVED STATUS */
                ...(userType === USER_TYPES.LE && status === STATUS_TYPES.APPROVED
                    ? [
                        { path: PATHS.ROOT, element: <Navigate to={PATHS.DASHBOARD} replace /> },
                        { path: PATHS.DASHBOARD, element: <DashboardPage /> },
                        { path: PATHS.ONBOARDING_FORM, element: <OnboardingFormPage /> },
                        { path: PATHS.LENDING_JOURNEY, element: <LandingJourneyPage /> },
                        { path: PATHS.LEGAL_AGREEMENT, element: <LegalAgreementPage /> },
                        { path: PATHS.IP_WHITELISTING, element: <IPWhitelistingPage /> },
                        { path: PATHS.API_MANAGER, element: <ApiManagerPage /> },
                    ]
                    : []),

                /* ROUTE ACCESSIBLE BY LE USERS WITH PENDING, SUBMITTED, REVIEWED, REJECTED STATUS */
                ...(userType === USER_TYPES.LE && [STATUS_TYPES.PENDING, STATUS_TYPES.SUBMITTED, STATUS_TYPES.REVIEWED, STATUS_TYPES.REJECTED].includes(status)
                    ? [
                        { path: PATHS.ROOT, element: <Navigate to={PATHS.DASHBOARD} replace /> },
                        { path: PATHS.DASHBOARD, element: <DashboardPage /> },
                        { path: PATHS.ONBOARDING_FORM, element: <OnboardingFormPage /> },
                        { path: PATHS.LEGAL_AGREEMENT, element: <LegalAgreementPage /> },
                    ]
                    : []),

                /* ROUTE ACCESSIBLE BY IT USERS WITH APPROVED STATUS */
                ...(userType === USER_TYPES.IT && status === STATUS_TYPES.APPROVED
                    ? [
                        { path: PATHS.ROOT, element: <Navigate to={PATHS.IP_WHITELISTING} replace /> },
                        { path: PATHS.IP_WHITELISTING, element: <IPWhitelistingPage /> },
                        { path: PATHS.API_MANAGER, element: <ApiManagerPage /> },
                    ]
                    : []),

                /* REDIRECT TO UNKNOWN ROUTES */
                { path: PATHS.WILDCARD, element: <Navigate to={determineRedirectPath()} replace /> },
            ],
        },
        {
            element: <AuthLayout />,
            children: [
                { path: PATHS.LOGIN, element: <LoginPage /> },
                { path: PATHS.RESET_PASSWORD, element: <ResetPasswordPage /> },
                { path: PATHS.WILDCARD, element: <Navigate to={PATHS.LOGIN} replace /> },
            ],
        },
    ]);

    return routes;
};

export default RouteWrapper;
import { ChangeEvent, FC, MouseEvent, useRef } from 'react';
import { FormikProps } from 'formik';
import { AttachCopyPinIcon, CrossIcon } from '../../assets';
import { showDocFileName } from '../../helpers';
import { FILE_EXTENSION_TYPES } from '../../data';

interface FileUploadProps {
    formik: FormikProps<any>;
    fieldName: string;
    accept?: string;
    fileName: string;
    isDisabled?: boolean;
}

const FileUpload: FC<FileUploadProps> = ({ formik, fieldName, isDisabled = false, accept = FILE_EXTENSION_TYPES.PDF, fileName }) => {
    const file = formik.values[fieldName] as File | null | string;
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!isDisabled) {
            const selectedFile = event.currentTarget.files?.[0];
            if (selectedFile) {
                formik.setFieldTouched(fieldName, true, true);
                formik.setFieldValue(fieldName, selectedFile);
                formik.validateField(fieldName);
            }
        }
    };

    const handleClearFile = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (!isDisabled) {
            formik.setFieldValue(fieldName, null);
            if (fileInputRef?.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    return (
        <div className={`file-upload-wrapper ${file ? 'file-selected' : ''}`} id={`${fieldName}-wrapper`}>
            <input
                type="file"
                id={fieldName}
                className="file-upload-input"
                ref={fileInputRef}
                onChange={handleFileChange}
                disabled={isDisabled}
                accept={accept}
                onBlur={formik.handleBlur}
                title={file ? fileName : "No File Choosen"}
            />
            <label htmlFor={fieldName} className={`file-upload-label ${file ? 'file-selected' : ''}`}>
                <span className="file-upload-text">
                    {showDocFileName(file, fileName)}
                </span>

                {file && (
                    <button
                        type="button"
                        className="clear-button"
                        onClick={handleClearFile}
                        disabled={isDisabled}
                    >
                        <img src={CrossIcon} alt="Delete" />
                    </button>
                )}

                <span className="file-upload-icon">
                    <img src={AttachCopyPinIcon} alt="Icon" />
                </span>
            </label>
        </div>
    );
};

export default FileUpload;

/* PATHS */
export const PATHS = {
    DASHBOARD: "/dashboard",
    USER_PROFILE: "/user-profile",
    ONBOARDING_FORM: "/onboarding-form",
    LENDING_JOURNEY: "/lending-journey",
    LEGAL_AGREEMENT: "/legal-agreement",
    IP_WHITELISTING: "/ip-whitelisting",
    API_MANAGER: "/api-manager",
    LOGIN: "/login",
    RESET_PASSWORD: "/reset-password",
    ROOT: "/",
    WILDCARD: "*",
};

/* HEADER TITLES */
export const TITLES = {
    [PATHS.DASHBOARD]: 'Dashboard',
    [PATHS.ONBOARDING_FORM]: 'Onboarding Form',
    [PATHS.IP_WHITELISTING]: 'IP Whitelisting',
    [PATHS.LENDING_JOURNEY]: 'Lending Journey',
    [PATHS.LEGAL_AGREEMENT]: 'Legal Agreement',
    [PATHS.API_MANAGER]: 'API Manager',
    [PATHS.USER_PROFILE]: 'User Profile',
    [PATHS.LOGIN]: 'Login',
    [PATHS.RESET_PASSWORD]: 'Reset Password',
};

export const note = "Note : Please attach a document with a minimum size of 30 KB and maximum size of 100 KB."

export const ACTION_TYPES = {
    EDIT: "Edit",
    VIEW: "View",
    ADD: "Add",
    DELETE: "Delete",
}

export const USER_TYPES = {
    LE: "LE",
    ES: "ES",
    IT: "IT"
}

export const CONTACT_PERSON_TYPES = {
    BUSINESS: "Business",
    IT: "IT",
    ESCALATION: "Escalation",
}

export const STATUS_TYPES = {
    PENDING: "pending",
    COMPLETED: "completed",
    APPROVED: "approved",
    REJECTED: "rejected",
    WHITELISTED: "whitelisted",
    BLACKLISTED: "blacklisted",
    ACCEPTED: "accepted",
    SUBMITTED: "submitted",
    REVIEWED: "reviewed",
    SUBSCRIBED: "subscribed",
    UNSUBSCRIBED: "unsubscribed",
}

export const ID_TYPES = {
    AADHARCARD: "aadharcard",
    PANCARD: "pancard",
    PASSPORT: "passport",
}

export const ID_PROOF_TYPE_OPTIONS = [
    { id: ID_TYPES.PANCARD, label: "Pan Card", disabled: false },
    { id: ID_TYPES.AADHARCARD, label: "Aadhar Card", disabled: false },
    { id: ID_TYPES.PASSPORT, label: "Passport", disabled: false },
];

export const IP_STATUS_OPTIONS = [
    { id: STATUS_TYPES.WHITELISTED, label: "Whitelisted", disabled: false },
    { id: STATUS_TYPES.BLACKLISTED, label: "Blacklisted", disabled: false },
    { id: STATUS_TYPES.SUBMITTED, label: "Pending", disabled: true },
];

export const requestedJourneys = [
    { id: "1", name: "Journey1", status: "Approved", documentId: "3905d224-5e86-408a-aa3c-3312d25c99c7", comments: "Looking good" },
    { id: "1", name: "Shekhar", status: "Pending", documentId: "3905d224-5e86-408a-aa3c-3312d25c99c7", comments: "Needs review" },
    { id: "1", name: "Priyank", status: "Rejected", documentId: "3905d224-5e86-408a-aa3c-3312d25c99c7", comments: "Not suitable" },
    { id: "1", name: "Asim", status: "Approved", documentId: "3905d224-5e86-408a-aa3c-3312d25c99c7", comments: "Accepted" }
];


export const FILE_TYPES = {
    PDF: 'application/pdf',
    PNG: 'image/png',
    JPEG: 'image/jpeg',
    JPG: 'image/jpg',
    GIF: 'image/gif',
    MP4: 'video/mp4',
    MP3: 'audio/mpeg',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ZIP: 'application/zip',
    CSV: 'text/csv',
    JSON: 'application/json',
};

export const FILE_EXTENSION_TYPES = {
    PDF: '.pdf',
    PNG: '.png',
    JPEG: '.jpeg',
    JPG: '.jpg',
    GIF: '.gif',
    MP4: '.mp4',
    MP3: '.mp3',
    DOC: '.doc',
    DOCX: '.docx',
    XLSX: '.xlsx',
    PPTX: '.pptx',
    ZIP: '.zip',
    CSV: '.csv',
    JSON: '.json',
};

/* API PACKAGES TABS */
export const API_PACKAGES_TABS = {
    ALL_SUBSCRIPTIONS: "All",
    MY_SUBSCRIPTIONS: "My Subscriptions",
}

/* API PACKAGES TABS */
export const API_PAGES = {
    API_MANAGER: "ApiManager",
    API_PACKAGES: "ApiPackages",
}

/* PDF FILE SIZE VALIDATIONS */
export const PDF_FILE_SIZE_VALIDATIONS = {
    MINIMUM_SIZE: 30 * 1024,// 30 KB
    MAXIMUM_SIZE: 100 * 1024, // 100 KB
}
/* MAGE FILE SIZE VALIDATIONS */
export const IMG_FILE_SIZE_VALIDATIONS = {
    MINIMUM_SIZE: 10 * 1024,// 10 KB
    MAXIMUM_SIZE: 100 * 1024, // 100 KB
}

export const REQUESTED_API_MESSAGE = "Your API subscription request has been successfully submitted. We’ll notify you once it has been approved.";
export const CONTACT_PERSON_NOTE = "A minimum of 2 and a maximum of 4 persons are mandatory.";
export const FINAL_SUBMISSION_NOTE = "I declare that the information provided in this form is true and accurate to the best of my knowledge.";
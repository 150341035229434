interface IProps {
    height?: string
}

const Loader = ({ height = "400px" }: IProps) => {
    return (
        <div className="loader-container" style={{ height }}>
            <div className="loader" />
        </div>
    )
}

export default Loader;

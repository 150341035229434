import Select from "react-select";
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetCategoriesQuery, useLazyGetCompanyDetailsQuery, useLazyGetSubscribedApiQuery, usePutSubscribeApiMutation } from '../../api';
import { RootState } from '../../redux/store';
import { convertBase64ToDataUrl } from '../../helpers';
import { showToast } from '../../redux/slices';
import { API_PAGES, STATUS_TYPES } from "../../data";
import { ImagePlaceholder, SearchIcon } from '../../assets';
import Loader from '../loader/Loader';
import NoRecordsFound from '../no-records-found/NoRecordsFound';

interface IProps {
    tab: string;
    page: string;
}

interface ApiCardProps {
    logo: string;
    name: string;
    description: string;
    provider: string;
    id: string;
    apiId: string
    apiCategoryIds: number[];
}

interface ApiCategory {
    id: number;
    name: string;
}

interface ApiDetails {
    id: string;
    name: string;
    description: string;
    provider: string;
    logo: string;
    apiCategories: ApiCategory[];
}

interface SubscribedApiItem {
    id: string;
    status: string;
    api: ApiDetails;
    apiCategoryIds: number[];
}

const MySubscriptions = ({ tab, page }: IProps) => {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
    const [getAllCategories] = useLazyGetCategoriesQuery();
    const [allCategories, setAllCategories] = useState<any[]>([]);
    const [putSubscribeApi] = usePutSubscribeApiMutation();
    const [getCompanyDetails] = useLazyGetCompanyDetailsQuery();
    const [getAllSubscribedApi, { isLoading, data: allSubscribed }] = useLazyGetSubscribedApiQuery();
    const [filteredData, setFilteredData] = useState<ApiCardProps[]>([]);
    const [loading, setLoading] = useState(false);
    const bankId = useSelector((state: RootState) => state.auth.bank?.id);

    /* HANDLE SEARCH */
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value);

    const isCategorySelected = (api: ApiCardProps, selectedCategories: number[]) => (selectedCategories.length === 0 || api?.apiCategoryIds?.some((categoryId: number) => selectedCategories.includes(categoryId)));

    const isSearchMatch = (api: ApiCardProps, searchQuery: string) => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        return api.name.toLowerCase().includes(lowerCaseQuery) ||
            api.description.toLowerCase().includes(lowerCaseQuery) ||
            api.provider.toLowerCase().includes(lowerCaseQuery);
    };

    const filteredDataToDisplay = filteredData?.filter((api: ApiCardProps) => isCategorySelected(api, selectedCategories) && isSearchMatch(api, searchQuery));

    /* HANDLE CATEGORY CHANGE */
    const handleCategoryChange = (selectedOptions: any) => setSelectedCategories(selectedOptions ? selectedOptions.map((option: { value: number }) => option.value) : []);

    const handleUnsubscribe = (id: string) => {
        if (!id) {
            dispatch(showToast({ message: "Invalid Subscribed API", type: 'error' }));
            return;
        }
        const payload = { id, status: STATUS_TYPES.UNSUBSCRIBED };
        putSubscribeApi({ subId: id, payload })
            .unwrap()
            .then(() => dispatch(showToast({ message: "API unsubscription requested successfully.", type: 'success' })))
            .catch((error) => dispatch(showToast({ message: error?.data?.message, type: 'error' })));
    };

    /* CHECK IF API IS SUBSCRIBED (STATUS == APPROVED) */
    const isSubscribed = (apiId: string) => allSubscribed?.body?.some((subscribedApi: { api: ApiCardProps, status: string }) => subscribedApi?.api?.id === apiId && subscribedApi.status === STATUS_TYPES.APPROVED);

    /* CHECK IF API IS REQUESTED (STATUS == UNSUBSCRIBED) */
    const isUnsubscribedRequested = (apiId: string) => allSubscribed?.body?.some((subscribedApi: { api: ApiCardProps, status: string }) => subscribedApi?.api?.id === apiId && subscribedApi.status === STATUS_TYPES.UNSUBSCRIBED);

    /* FORMAT RESPONSE */
    const extractSubscribedApis = useCallback((subscribedApiItems: SubscribedApiItem[]) => {
        if (page === API_PAGES.API_MANAGER) {
            return subscribedApiItems
                ?.filter(item => item?.status === STATUS_TYPES.APPROVED || item?.status === STATUS_TYPES.UNSUBSCRIBED)
                ?.map((subscribedApiItem) => {
                    const { id, api } = subscribedApiItem;
                    return {
                        id,
                        logo: api?.logo,
                        apiId: api?.id,
                        name: api?.name,
                        description: api?.description,
                        provider: api?.provider,
                        apiCategoryIds: api?.apiCategories?.length > 0 ? api.apiCategories.map(({ id }) => id) : [],
                    };
                });
        } else {
            return subscribedApiItems.map((subscribedApiItem) => {
                const { id, api } = subscribedApiItem;
                return {
                    id,
                    logo: api?.logo,
                    apiId: api?.id,
                    name: api?.name,
                    description: api?.description,
                    provider: api?.provider,
                    apiCategoryIds: api?.apiCategories?.length > 0 ? api.apiCategories.map(({ id }) => id) : [],
                };
            });
        }
    }, [page]);

    useEffect(() => {
        /* COMPANY DETAILS */
        getCompanyDetails(bankId).unwrap();
    }, [bankId, getCompanyDetails]);


    useEffect(() => {
        setLoading(true);
        // Fetch categories
        getAllCategories({})
            .unwrap()
            .then((res) => {
                const cats = res?.body;
                if (cats?.length > 0) {
                    const formattedCategories = cats.map((cat: ApiCategory) => ({
                        value: cat?.id,
                        label: cat?.name
                    }));
                    setAllCategories(formattedCategories);
                }
            })
            .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Internal Server Error! Please try again later.', type: 'error' })));

        // Fetch subscribed APIs
        if (bankId) {
            getAllSubscribedApi(bankId)
                .unwrap()
                .then((response) => {
                    if (response?.body) {
                        setFilteredData(extractSubscribedApis(response?.body));
                    }
                })
                .catch((error) => dispatch(showToast({ message: error?.data?.message ?? 'Internal Server Error! Please try again later.', type: 'error' })))
                .finally(() => setLoading(false));
        }
    }, [dispatch, getAllCategories, getAllSubscribedApi, bankId, allSubscribed, extractSubscribedApis]);


    return (
        <div>
            <div className="main-search-box common-flex">
                <div className="top-search-box common-flex">
                    <div className="form-group">
                        <img src={SearchIcon} alt="search" />
                        <input
                            type="search"
                            className="form-control"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
                <div className="right-btn-box">
                    <div className="form-group">
                        <Select
                            isMulti
                            options={allCategories}
                            onChange={handleCategoryChange}
                            placeholder="Select Categories"
                            value={allCategories.filter(category => selectedCategories.includes(category.value))}
                            isClearable={true}
                            closeMenuOnSelect={false}
                            styles={{
                                control: (styles) => ({
                                    ...styles,
                                    backgroundColor: "#f5f7fc",
                                    borderWidth: 1,
                                    padding: 5,
                                    borderRadius: 5,
                                    minHeight: '40px',
                                    minWidth: '400px',
                                    maxWidth: '800px',
                                }),
                            }}
                        />
                    </div>
                </div>
            </div>
            {isLoading || loading ? (
                <Loader height="400px" />
            ) : (
                !loading && filteredDataToDisplay?.length <= 0 ? (
                    <NoRecordsFound message="No Subscribed APIs Found!" height="400px" />
                ) : (
                    <section className="main-api-cards-section">
                        {filteredDataToDisplay?.map((api: ApiCardProps, index: number) => {
                            const subscribed = isSubscribed(api?.apiId);
                            const unsubscribedRequested = isUnsubscribedRequested(api?.apiId);

                            let buttonLabel = '';
                            let buttonClass = 'btn light-btn green-btn';
                            if (subscribed) {
                                buttonLabel = "Unsubscribe";
                            } else if (unsubscribedRequested) {
                                buttonLabel = "Unsubscribe Requested";
                                buttonClass = 'btn light-btn gray-btn';
                            } else {
                                buttonLabel = "Subscribe";
                            }

                            return (
                                <div className="common-card-box" key={index}>
                                    <div className="logo-box">
                                        <img src={api?.logo ? convertBase64ToDataUrl(api?.logo) : ImagePlaceholder} alt={`${api?.name}-logo`} />
                                    </div>
                                    <h4>{api?.name}</h4>
                                    <p>{api?.description}</p>
                                    <div className="tags-box">
                                        {api?.provider && api?.provider?.trim() !== '' && api?.provider?.split(',')?.length > 0 && (
                                            api?.provider?.split(',').map((tag, index) => (
                                                <button type="button" className="tagbox" key={index}>
                                                    <span>{tag?.trim()}</span>
                                                </button>
                                            ))
                                        )}
                                    </div>
                                    <div className="card-btn-box">
                                        <button
                                            type="button"
                                            className={buttonClass}
                                            onClick={() => {
                                                if (subscribed) {
                                                    handleUnsubscribe(api?.id);
                                                } else if (unsubscribedRequested) {
                                                    dispatch(showToast({ message: "You've already requested for unsubscribe this API", type: 'error' }));
                                                } else {
                                                    dispatch(showToast({ message: "Not Subscribed", type: 'error' }));
                                                }
                                            }}
                                        >
                                            {buttonLabel}
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </section>
                )
            )}
        </div>
    );
};

export default MySubscriptions;
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddIcon, DesignatedContactIcon, NextIcon, PreviousIcon } from "../../assets";
import { ACTION_TYPES, CONTACT_PERSON_NOTE, STATUS_TYPES } from "../../data";
import { useLazyGetContactPersonsQuery, useLazyGetDesignationsQuery } from "../../api";
import { RootState } from "../../redux/store";
import { DeleteDesignatedContactPersonModal, DesignatedContactPersonModal } from "../modals";
import { getDesignatedContactPersonFilterKey } from "../../helpers";
import { showToast } from "../../redux/slices";
import DesignatedContactPersonTable from "../tables/DesignatedContactPersonTable";

interface IProps {
    header: string;
    handleNextStep: () => void;
    handlePreviousStep: () => void;
}

const DesignatedContactPersonForm = (props: IProps) => {
    const dispatch = useDispatch();
    const [isAddEditViewModalOpen, setIsAddEditViewModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const { header, handleNextStep, handlePreviousStep } = props;
    const [getDesignations, { isLoading: isDesignationLoading, data: designations }] = useLazyGetDesignationsQuery();
    const [getContactPerson, { data, isLoading: isGetContactPersonLoading }] = useLazyGetContactPersonsQuery();
    const [actionType, setActionType] = useState(ACTION_TYPES.ADD);
    const [personId, setPersonId] = useState<string | null>(null);
    const bank = useSelector((state: RootState) => state.auth.bank);
    const isFormApproved = bank?.status === STATUS_TYPES.APPROVED;
    const totalContactPersons = data?.body && data?.body?.filter((person: { usertype: string }) => person?.usertype === getDesignatedContactPersonFilterKey(header))?.length;
    const openAddEditViewModal = () => setIsAddEditViewModalOpen(true);
    const closeAddEditViewModal = () => setIsAddEditViewModalOpen(false);
    const openDeleteModal = () => setIsDeleteModalOpen(true);
    const closeDeleteModal = () => setIsDeleteModalOpen(false);


    /* GET DESIGNATIONS */
    const getAllDesignations = useCallback(() => {
        getDesignations({})
            .unwrap()
            .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Internal Server Error! Please try again later.', type: 'error' })));
    }, [dispatch, getDesignations]);

    const handleAdd = () => {
        setActionType(ACTION_TYPES.ADD);
        openAddEditViewModal();
        getAllDesignations();
    }

    const handleView = (id: string) => {
        setActionType(ACTION_TYPES.VIEW);
        openAddEditViewModal();
        setPersonId(id)
        getAllDesignations();
    }

    const handleEdit = (id: string) => {
        setActionType(ACTION_TYPES.EDIT);
        openAddEditViewModal();
        setPersonId(id)
        getAllDesignations();
    }

    const handleDelete = (id: string) => {
        setActionType(ACTION_TYPES.DELETE);
        openDeleteModal();
        setPersonId(id);
    }

    const handleCloseModal = () => {
        closeDeleteModal();
        closeAddEditViewModal();
        setPersonId(null);
        setActionType(ACTION_TYPES.ADD)
    }

    const handleNext = () => {
        /* IF FORM APPROVED THEN SKIPS OTHER THINGS */
        if (isFormApproved) {
            handleNextStep();
            return;
        }

        /* CHECK CONTACT PERSON  */
        if (totalContactPersons < 2) {
            dispatch(showToast({ message: "Please add minimum 2 contact persons!", type: 'error' }));
            return
        }
        handleNextStep();
    };

    useEffect(() => {

        /* GET DESIGNATIONS */
        getAllDesignations();

        /* DESIGNATED CONTACT PERSON */
        getContactPerson(bank?.id)
            .unwrap()
            .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Failed!', type: 'error' })));
    }, [bank?.id, dispatch, getAllDesignations, getContactPerson]);

    return (
        <>
            <div className="panel panel-primary setup-content" id="step-2">
                <div className="onboarding-form">
                    <div className="company-details-form common-p-20 bg-white-border">
                        <div className="title-box">
                            <div className="icon-box">
                                <img src={DesignatedContactIcon} alt='designated-contact-person' />
                            </div>
                            <h4>Designated Contact Person ({header})</h4>
                        </div>
                        {(totalContactPersons < 4 && !isFormApproved) && (
                            <button
                                type="button"
                                className="light-btn btn add-btn add-form-btn"
                                onClick={() => handleAdd()}
                            >
                                <img src={AddIcon} alt='add' />
                                Add
                            </button>
                        )}

                        <div className="table-box">
                            <DesignatedContactPersonTable
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                handleView={handleView}
                                header={header}
                                isLoading={isGetContactPersonLoading}
                                designatedPersons={data?.body}
                            />
                        </div>
                        <p className="note border-top">Note : {CONTACT_PERSON_NOTE}</p>
                        <ul className="main-btn-group pager">
                            <li className="prev-btn-wrapper" onClick={handlePreviousStep}>
                                <button type="button" className="line-btn btn prevBtn">
                                    <img src={PreviousIcon} alt="prev icon" className="prev-icon" />
                                    Previous
                                </button>
                            </li>

                            <li className="next-btn-wrapper" onClick={handleNext}>
                                <button type="button" className="line-btn btn nextBtn">
                                    Next
                                    <img src={NextIcon} alt="next icon" className="next-icon" />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <DesignatedContactPersonModal
                open={isAddEditViewModalOpen}
                closeModal={handleCloseModal}
                header={header}
                actionType={actionType}
                personId={personId}
                isDesignationLoading={isDesignationLoading}
                designationOptions={designations?.body}
            />

            <DeleteDesignatedContactPersonModal
                open={isDeleteModalOpen}
                closeModal={handleCloseModal}
                personId={personId}
                actionType={actionType}
            />
        </>
    );
};

export default DesignatedContactPersonForm;
import Modal from "react-responsive-modal";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetSingleContactPersonQuery, usePostContactPersonMutation, usePutContactPersonMutation } from "../../api";
import { ACTION_TYPES, ID_PROOF_TYPE_OPTIONS, STATUS_TYPES } from "../../data";
import { showToast } from "../../redux/slices";
import { RootState } from "../../redux/store";
import { businessContactPersonValSchema } from "../../validations";
import { getDesignatedContactPersonFilterKey } from "../../helpers";
import CustomSelect from "../custom-select/CustomSelect";
import FileUpload from "../file-upload/FileUpload";
import Loader from "../loader/Loader";

interface IDesignationOption {
    id: number;
    name: string;
};

interface IProps {
    open: boolean;
    isDesignationLoading: boolean;
    actionType: string;
    header: string;
    closeModal: () => void;
    minWidth?: string;
    personId: string | null;
    designationOptions: IDesignationOption[];
}

interface IContactPerson {
    id?: string;
    bankId: string;
    firstName: string;
    lastName: string;
    designation: number;
    department: string;
    email: string;
    usertype: string;
    mobile: string;
    idProofType: string;
    idProofNo: string;
    idProofDocId: File | null;
}

const DesignatedContactPersonModal = (props: IProps) => {
    const { actionType, open, header, closeModal, minWidth = "60vw", personId, designationOptions, isDesignationLoading } = props;
    const isViewType = actionType === ACTION_TYPES.VIEW;
    const isEditType = actionType === ACTION_TYPES.EDIT;
    const isAddType = actionType === ACTION_TYPES.ADD;
    const dispatch = useDispatch();
    const bank = useSelector((state: RootState) => state.auth.bank);
    const isFormApproved = bank?.status === STATUS_TYPES.APPROVED;
    const userEmail = useSelector((state: RootState) => state.auth.user?.email);
    const [postContactPerson, { isLoading: isPostCPLoading }] = usePostContactPersonMutation();
    const [putContactPerson, { isLoading: isPutCPLoading }] = usePutContactPersonMutation();
    const [getContactPerson, { isLoading }] = useLazyGetSingleContactPersonQuery();

    const formik = useFormik({
        initialValues: {
            id: '',
            firstName: '',
            lastName: '',
            designation: 0,
            department: '',
            email: '',
            mobile: '',
            idProofType: '',
            idProofNo: '',
            idProofDocId: null,
            usertype: getDesignatedContactPersonFilterKey(header),
            bankId: bank?.id,
        },
        enableReinitialize: true,
        validationSchema: businessContactPersonValSchema,
        onSubmit: (values: IContactPerson) => {
            const payload = new FormData();

            /* RENAME FILE FIELDS TO KEY NAMES */
            const fieldMapping: { [key: string]: string } = {
                idProofDocId: 'idProofDoc',
            };

            Object.keys(values).forEach(key => {
                const value = values[key as keyof IContactPerson];
                let formKey = key;

                /* CHECK MAPPING FOR FILEs */
                if (value instanceof File && fieldMapping[key]) {
                    formKey = fieldMapping[key];
                }

                /* ADD ID ONLY EDIT */
                if (key === 'id' && !isEditType) {
                    return;
                }

                if (value instanceof File) {
                    /* APPENDING FILES */
                    payload.append(formKey, value);
                } else {
                    /* APPENDING STRINGS */
                    payload.append(formKey, value as string);
                }
            });

            if (isEditType) {
                const contactId = values?.id;
                putContactPerson({ contactId, payload })
                    .unwrap()
                    .then((res) => {
                        dispatch(showToast({ message: res?.message || 'Saved!', type: 'success' }));
                        formik?.resetForm();
                        handleCancel();
                    })
                    .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Internal Server Error! Please try again later.', type: 'error' })));
            }
            if (isAddType) {
                postContactPerson(payload)
                    .unwrap()
                    .then((res) => {
                        dispatch(showToast({ message: res?.message || 'Saved!', type: 'success' }));
                        formik?.resetForm();
                        handleCancel();
                    })
                    .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Internal Server Error! Please try again later.', type: 'error' })));
            }
        }
    });

    const handleCancel = () => {
        formik.resetForm();
        closeModal();
    };

    useEffect(() => {

        if (actionType === ACTION_TYPES.ADD || actionType === ACTION_TYPES.DELETE) {
            return;
        }

        if (!personId) {
            dispatch(showToast({ message: "Invalid Contact Person!", type: 'error' }));
            return;
        }

        getContactPerson(personId)
            .unwrap()
            .then((res) => {
                const personDetails = res?.body;
                if (personDetails) {
                    formik.setValues({
                        ...formik.values,
                        id: personDetails?.id || '',
                        firstName: personDetails?.firstName || '',
                        lastName: personDetails?.lastName || '',
                        designation: personDetails?.designation?.id || 0,
                        department: personDetails?.department || '',
                        email: personDetails?.email || '',
                        mobile: personDetails?.mobile || '',
                        idProofType: personDetails?.idProofType || '',
                        idProofNo: personDetails?.idProofNo || '',
                        idProofDocId: personDetails?.idProofDocId || '', /* updated idProofDocId as idProofDocId */
                        usertype: personDetails?.usertype,
                        bankId: personDetails?.bank?.id,
                    });
                }
            })
            .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Internal Server Error! Please try again later.', type: 'error' })));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, getContactPerson, personId, actionType, bank?.id]);


    return (
        <Modal
            center
            open={open}
            onClose={() => handleCancel()}
            onEscKeyDown={() => handleCancel()}
            showCloseIcon
            styles={{ modal: { minWidth } }}
            classNames={{ modal: "custom-modal" }}
        >
            <form onSubmit={formik.handleSubmit}>
                {/* HEADER */}
                <div className="title-box">
                    <h5>{actionType} Designated Contact Person ({header})</h5>
                </div>

                {/* BODY */}
                <div className=" ">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label">
                                            First Name <span className="required-dot">*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            placeholder="Enter your first name"
                                            type="text"
                                            name="firstName"
                                            value={formik.values.firstName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled={isViewType || isFormApproved}
                                        />
                                        {formik?.touched.firstName && formik?.errors.firstName && (<div className="error-msg">{formik?.errors.firstName}</div>)}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label">
                                            Last Name <span className="required-dot">*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            placeholder="Enter your last name"
                                            type="text"
                                            name="lastName"
                                            value={formik.values.lastName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled={isViewType || isFormApproved}
                                        />
                                        {formik?.touched?.lastName && formik.errors?.lastName && (<div className="error-msg">{formik.errors.lastName}</div>)}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label">
                                            Designation <span className="required-dot">*</span>
                                        </label>
                                        <select
                                            id="designation"
                                            name="designation"
                                            className="form-select"
                                            aria-label="Select Designation"
                                            value={formik.values.designation}
                                            onChange={(e) => formik.setFieldValue("designation", e.target.value)}
                                            onBlur={formik.handleBlur}
                                            disabled={isViewType || isFormApproved}
                                        >
                                            <option value={0} disabled>
                                                Select
                                            </option>
                                            {/* SELECT */}
                                            {isDesignationLoading
                                                ? (<option><Loader height="100px" /></option>)
                                                : designationOptions?.length <= 0
                                                    ? (<option>No Options Available</option>)
                                                    : (
                                                        designationOptions?.map(({ id, name }: IDesignationOption) => (
                                                            <option key={id} value={id}>{name}</option>
                                                        ))
                                                    )
                                            }
                                        </select>
                                        {formik?.touched?.designation && formik.errors?.designation && (<div className="error-msg">{formik.errors.designation}</div>)}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label">
                                            Business Unit/Department <span className="required-dot">*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            placeholder="Enter your department"
                                            type="text"
                                            name="department"
                                            value={formik.values.department}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled={isViewType || isFormApproved}
                                        />
                                        {formik?.touched?.department && formik.errors?.department && (<div className="error-msg">{formik.errors.department}</div>)}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label">
                                            Email ID <span className="required-dot">*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            placeholder="Enter your email"
                                            type="email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={e => formik.setFieldValue('email', e?.target?.value?.toLowerCase())}
                                            onBlur={formik.handleBlur}
                                            disabled={isViewType || isFormApproved || (isEditType && formik.values.email?.toLowerCase() === userEmail?.toLowerCase())}
                                        />
                                        {formik?.touched?.email && formik.errors?.email && (<div className="error-msg">{formik.errors.email}</div>)}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label">
                                            Mobile Number <span className="required-dot">*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            placeholder="Enter your mobile number"
                                            type="text"
                                            name="mobile"
                                            value={formik.values.mobile}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled={isViewType || isFormApproved}
                                        />
                                        {formik?.touched?.mobile && formik.errors?.mobile && (<div className="error-msg">{formik.errors.mobile}</div>)}
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="form-label">Select ID Proof <span className="required-dot">*</span></label>
                                        <CustomSelect
                                            id="idProofType"
                                            name="idProofType"
                                            className="form-select"
                                            parentClassName=""
                                            ariaLabel="ID Proof selection"
                                            options={ID_PROOF_TYPE_OPTIONS}
                                            value={formik.values.idProofType}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled={isViewType || isFormApproved}
                                        />
                                        {formik?.touched?.idProofType && formik.errors?.idProofType && (<div className="error-msg">{formik.errors.idProofType}</div>)}
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="form-label">
                                            ID Proof Number <span className="required-dot">*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            placeholder="Enter your ID proof number"
                                            type="text"
                                            name="idProofNo"
                                            onChange={e => formik.setFieldValue('idProofNo', e?.target?.value?.toUpperCase())}
                                            onBlur={formik.handleBlur}
                                            value={formik?.values?.idProofNo?.toUpperCase()}
                                            disabled={isViewType || isFormApproved}
                                        />
                                        {formik?.touched?.idProofNo && formik.errors?.idProofNo && (<div className="error-msg">{formik.errors.idProofNo}</div>)}
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label htmlFor="formFile" className="form-label">Attach ID Proof <span className="required-dot">*</span></label>
                                        <FileUpload
                                            fieldName='idProofDocId'
                                            formik={formik}
                                            isDisabled={isViewType || isFormApproved}
                                            fileName="ID Proof.pdf"
                                        />
                                        {formik?.touched?.idProofDocId && formik.errors?.idProofDocId && (<div className="error-msg">{formik.errors.idProofDocId}</div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* FOOTER */}
                <div className="modal-btn-footer">
                    <button type="button" className="line-btn btn" onClick={() => handleCancel()}>{isViewType ? "Close" : "Cancel"}</button>
                    {!isViewType && <button type="submit" disabled={isPutCPLoading || isPostCPLoading || isFormApproved} className="btn common-btn">
                        {(isPutCPLoading || isPostCPLoading) && <div className="submit-button-loader" />}
                        Save
                    </button>}
                </div>

            </form>
        </Modal>
    );
};

export default DesignatedContactPersonModal;

/* HELPER FUNCTION TO FORMAT MOBILE NO*/
const formatPhoneNo = (mobile: undefined | null | string) => {
    if (!mobile) {
        return '-';
    }

    /* REMOVE NON DIGIT CHARACTER */
    const cleaned = mobile?.replace(/\D/g, '');

    /* CHECK LENGTH IS 10  */
    if (cleaned?.length === 10) {
        return `${cleaned.slice(0, 5)} ${cleaned.slice(5)}`;
    }

    return mobile;
};

export default formatPhoneNo;
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
// import { Suspense } from "react";
// import { PersistGate } from "redux-persist/integration/react";
// import { ToastContainer } from "react-toastify";
// import { Provider } from "react-redux";
// import { persistStore } from "redux-persist";
// import { BrowserRouter } from "react-router-dom";
// import { store } from "./redux/store";
// import { LoadingFallback } from "./components";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';
import './styles/easy-responsive-tabs.css'
import "./styles/pixie-main.css";
import "./styles/pixie-media.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);


// const persistor = persistStore(store);

// root.render(
//   <StrictMode>
//     <Suspense fallback={<LoadingFallback />}>
//       <Provider store={store}>
//         <PersistGate loading={null} persistor={persistor}>
//           <BrowserRouter>
//             <App />
//             <ToastContainer />
//           </BrowserRouter>
//         </PersistGate>
//       </Provider>
//     </Suspense>
//   </StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
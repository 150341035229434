import { FILE_TYPES } from "../data";

const convertBase64ToBlob = (base64: string, type = FILE_TYPES.PDF) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters?.length; i++) {
        byteNumbers[i] = byteCharacters?.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type });
};

export default convertBase64ToBlob;
import { useSelector } from "react-redux";
import { DeleteIcon, EditIcon, EyeIcon } from "../../assets";
import { getDesignatedContactPersonFilterKey } from "../../helpers";
import { RootState } from "../../redux/store";
import NoRecordsFound from "../no-records-found/NoRecordsFound";
import Loader from "../loader/Loader";

interface IProps {
    designatedPersons: IContactPerson[];
    isLoading: boolean
    header: string
    handleView: (id: string) => void;
    handleEdit: (id: string) => void;
    handleDelete: (id: string) => void;
}

interface IContactPerson {
    id: string;
    firstName: string;
    lastName: string;
    designation: {
        id: number
        name: string;
    }
    department: string;
    email: string;
    mobile: string;
    idProofType: string;
    idProofNo: string;
    usertype: string;
    idProofDoc: File | null;
    bankId: string
    contactLvl: number;
    idProofDocId: string
}

const DesignatedContactPersonTable = (props: IProps) => {
    const { designatedPersons, header, isLoading, handleView, handleEdit, handleDelete, } = props;
    const headers = ["No.", "Name", "Designation", "Department", "Email", "Mobile", "Action"];
    const userEmail = useSelector((state: RootState) => state.auth.user?.email);
    return (
        <table className="table table-responsive common-table">
            <thead>
                <tr>
                    {headers.map((hed, index) => (
                        <th key={index}>{hed}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {isLoading
                    ? <tr>
                        <td colSpan={headers?.length}><Loader height="100px" /></td>
                    </tr>
                    : (() => {
                        const filteredData = designatedPersons?.filter((i: IContactPerson) => i.usertype === getDesignatedContactPersonFilterKey(header));
                        const filteredDataLength = filteredData?.length || 0;

                        return filteredDataLength > 0 ?
                            (
                                filteredData.map((dp: IContactPerson, index: number) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{dp?.firstName + " " + dp?.lastName}</td>
                                        <td>{dp?.designation?.name}</td>
                                        <td>{dp?.department}</td>
                                        <td>{dp?.email}</td>
                                        <td>{dp?.mobile}</td>
                                        <td className="action-box">
                                            <div className="edit-delete-view-box">
                                                <button type="button" className="icon-box view-icon" onClick={() => handleView(dp?.id)}>
                                                    <img src={EyeIcon} alt="view" />
                                                </button>
                                                <button type="button" className="icon-box edit-icon" onClick={() => handleEdit(dp?.id)}>
                                                    <img src={EditIcon} alt="edit" />
                                                </button>
                                                {dp?.email !== userEmail &&
                                                    (<button type="button" className="icon-box delete-icon" onClick={() => handleDelete(dp?.id)}>
                                                        <img src={DeleteIcon} alt="delete" />
                                                    </button>)}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={headers?.length}><NoRecordsFound height="100px" message="No Designated Persons Available!" /></td>
                                </tr>
                            );
                    })()
                }
            </tbody>
        </table>
    );
}


export default DesignatedContactPersonTable;
import { PATHS, STATUS_TYPES, TITLES, USER_TYPES } from '../data';

interface NavItem {
    path: string;
    title: string;
}

const getNavItems = (userType: string, status: string): NavItem[] => {
    /* COMMON ROUTES */
    const commonLenderItems = [
        { path: PATHS.DASHBOARD, title: TITLES[PATHS.DASHBOARD] },
        { path: PATHS.ONBOARDING_FORM, title: TITLES[PATHS.ONBOARDING_FORM] },
        { path: PATHS.LEGAL_AGREEMENT, title: TITLES[PATHS.LEGAL_AGREEMENT] },
    ];

    /* LENDER APPROVED LENDER ROUTES */
    const approvedLenderItems = [
        { path: PATHS.LENDING_JOURNEY, title: TITLES[PATHS.LENDING_JOURNEY] },
        { path: PATHS.IP_WHITELISTING, title: TITLES[PATHS.IP_WHITELISTING] },
        { path: PATHS.API_MANAGER, title: TITLES[PATHS.API_MANAGER] },
    ];

    /* IT ROUTE */
    const itItems = [
        { path: PATHS.IP_WHITELISTING, title: TITLES[PATHS.IP_WHITELISTING] },
        { path: PATHS.API_MANAGER, title: TITLES[PATHS.API_MANAGER] },
    ];

    if (userType === USER_TYPES.LE) {
        /* RETURN ROUTES BASED ON STATUS */
        if (status === STATUS_TYPES.APPROVED) {
            return [...commonLenderItems, ...approvedLenderItems];
        }

        return commonLenderItems;
    }

    if (userType === USER_TYPES.IT) {
        /* RETURN IT ROUTES */
        return status === STATUS_TYPES.APPROVED ? itItems : [];
    }

    /* RETURN EMPPTY IF NO CONDTIONS ARE MATCHED */
    return [];
};

export default getNavItems;

const formatDateToDDMMMYYYY = (input: Date | string): string => {
    /* CONVERT STRING TO DATE */
    const date = typeof input === 'string' ? new Date(input) : input;

    /* CHECK DATE VALIDATION */
    if (isNaN(date?.getTime())) {
        return '-';
    }

    /* FORMAT DATE INTO  DD-MM-YYYY */
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = date?.toLocaleString('default', { month: 'long' });
    const year = date?.getFullYear();

    return `${day} ${month} ${year}`;
}

export default formatDateToDDMMMYYYY;
import { ChangeEvent, FocusEvent } from "react";
import NoRecordsFound from "../no-records-found/NoRecordsFound";

interface OptionType {
  id: string | number;
  label: string;
  disabled?: boolean;
}

interface Props {
  id: string;
  name: string;
  options: OptionType[];
  value: string | number | undefined;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (e: FocusEvent<HTMLSelectElement>) => void;
  className: string;
  ariaLabel: string;
  parentClassName?: string;
  disabled?: boolean;
}

const CustomSelect = (props: Props) => {
  const { id, name, options, value, onChange, onBlur, className, ariaLabel, parentClassName, disabled } = props;
  return (
    <div className={parentClassName}>
      <select id={id} name={name} className={className} aria-label={ariaLabel} value={value} onChange={onChange} onBlur={onBlur} disabled={disabled}>
        <option value="" disabled>
          Select
        </option>
        {/* SELECT */}
        {
          options?.length <= 0 ? (
            <option disabled><NoRecordsFound message="No Options Available!" height="100px" /></option>
          ) : (
            options?.map(({ id, label, disabled }) => (
              <option key={id} value={id} disabled={disabled}>
                {label}
              </option>
            ))
          )}
      </select>
    </div>
  );
};

export default CustomSelect;

import { createSlice } from '@reduxjs/toolkit';
import { Bounce, toast } from 'react-toastify';

const initialState = {
  message: '',
  type: 'default',
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'colored',
  transition: Bounce,
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state, action) => {
      toast(action.payload.message, {
        type: action.payload.type || state.type,
        position: state.position,
        autoClose: state.autoClose,
        hideProgressBar: state.hideProgressBar,
        closeOnClick: state.closeOnClick,
        pauseOnHover: state.pauseOnHover,
        draggable: state.draggable,
        progress: state.progress,
        theme: state.theme,
        transition: state.transition,
        ...action.payload.options
      });
      return {
        ...state,
        message: action.payload.message,
        type: action.payload.type || state.type,
      };
    },
  },
});

export const { showToast } = toastSlice.actions;
export default toastSlice.reducer;



/* HOT TOAST SLICE */

// import { createSlice } from '@reduxjs/toolkit';
// import { toast } from 'react-hot-toast';

// const initialState = {
//   message: '',
//   type: 'default',
//   position: 'top-right',
//   duration: 1000,
// };

// export const toastSlice = createSlice({
//   name: 'toast',
//   initialState,
//   reducers: {
//     showToast: (state, action) => {
//       // Depending on the type, you can call different toast methods
//       const toastOptions = {
//         duration: state.duration,
//         position: state.position,
//       };

//       switch (action.payload.type) {
//         case 'success':
//           toast.success(action.payload.message, toastOptions);
//           break;
//         case 'error':
//           toast.error(action.payload.message, toastOptions);
//           break;
//         case 'loading':
//           toast.loading(action.payload.message, toastOptions);
//           break;
//         default:
//           toast(action.payload.message, toastOptions);
//           break;
//       }

//       return {
//         ...state,
//         message: action.payload.message,
//         type: action.payload.type || 'default',
//       };
//     },
//   },
// });

// export const { showToast } = toastSlice.actions;
// export default toastSlice.reducer;

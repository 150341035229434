import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLazyGetDownloadDocumentQuery } from "../../api";
import { DownloadIcon, DummyImage, PDFImage } from "../../assets";
import { docDownload } from "../../utils";
import { showToast } from "../../redux/slices";
import { convertBase64ToBlob, validateUUID } from "../../helpers";

interface IProps {
    title: string;
    id?: string | null;
    fileName: string;
    isImage?: boolean;
}

const DocumentPreview = ({ title, id, fileName, isImage }: IProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [getDocumentDownload] = useLazyGetDownloadDocumentQuery();

    /* DOWNLOAD DOCUMENT */
    const handleDownload = () => {
        if (!id) {
            dispatch(showToast({ message: "Document not uploaded yet.", type: 'error' }));
            return
        }
        if (isImage && id) {
            setIsLoading(true);
            const blobImage = convertBase64ToBlob(id);
            docDownload(blobImage, fileName);
            dispatch(showToast({ message: "Document downloaded successfully.", type: 'success' }));
            setIsLoading(false)
            return;
        }

        if (!validateUUID(id)) {
            dispatch(showToast({ message: "Invalid Document!", type: 'error' }));
            return;
        }

        if (!isImage) {
            setIsLoading(true)
            /* DOWNLOAD DOCUMENT */
            getDocumentDownload(id)
                .unwrap()
                .then((res) => {
                    if (res) {
                        docDownload(res, fileName);
                        dispatch(showToast({ message: "Document downloaded successfully.", type: 'success' }));
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    dispatch(showToast({ message: error?.data?.message || "Failed", type: 'error' }));
                    setIsLoading(false);
                });
        }
    }

    return (
        <div className="common-box">
            <div className="common-detail-box">
                <div className="img-box">
                    <img alt="img" src={isImage ? DummyImage : PDFImage} />
                </div>
                <div className="common-detail-cont">
                    <h5>{title}</h5>
                    <div className="d-flex align-items-center gap-1">
                        {id ?
                            (<p className="view-btn d-flex align-items-center gap-1" onClick={() => handleDownload()}>
                                Download {isLoading && <div className="downloading-button-loader" />}
                                {!isLoading && <img src={DownloadIcon} alt="Download Document" />}
                            </p>)
                            : (<p className="pink-text">Not Uploaded!</p>)}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DocumentPreview;
import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINT } from './endpoints';
import baseQuery from '../baseQuery';

export const onboardingApi = createApi({
    reducerPath: 'onboardingApi',

    baseQuery,

    tagTypes: [
        "get_company_details",
        "get_contact_person_details",
        "get_approved_journeys",
        "get_requested_journeys",
        "get_saved_journeys",
        "get_single_contact_person_details",
        "get_categories",
        "get_subscribed_apis",
    ],

    endpoints: (builder) => ({

        /* SUBMIT  */
        postSubmitForm: builder.mutation({
            query: ({ bankId, payload }) => ({
                url: `${ENDPOINT.POST_SUBMIT_FORM}/${bankId}`,
                body: payload,
                method: "PUT",
            }),
            invalidatesTags: ["get_company_details"]
        }),


        /* DOWNLOAD */
        getDownloadDocument: builder.query({
            query: (id) => ({
                url: `${ENDPOINT.GET_DOWNLOAD_FILE}/${id}`,
                method: "GET",
                responseHandler: (response) => response.blob(),
            }),
        }),

        /* GET COMPANY */
        getCompanyDetails: builder.query({
            query: (bankId) => ({
                url: `${ENDPOINT.GET_COMPANY_DETAILS}/${bankId}`,
                method: "GET",
            }),
            providesTags: ["get_company_details"]
        }),

        /* GET DESIGNATIONS */
        getDesignations: builder.query({
            query: () => ({
                url: ENDPOINT.GET_DESIGNATIONS,
                method: "GET",
            }),
        }),

        /* PUT COMPANY */
        putCompanyDetails: builder.mutation({
            query: ({ bankId, payload }) => ({
                url: `${ENDPOINT.PUT_COMPANY_DETAILS}/${bankId}`,
                body: payload,
                method: "PUT",
            }),
            invalidatesTags: ["get_company_details"],
        }),

        /* POST CONTACT PERSON */
        postContactPerson: builder.mutation({
            query: (data) => ({
                url: ENDPOINT.POST_CONTACT_PERSON_DETAILS,
                body: data,
                method: "POST",
            }),
            invalidatesTags: ["get_contact_person_details"]
        }),

        /* GET CONTACT PERSON */
        getContactPersons: builder.query({
            query: (bankId) => ({
                url: `${ENDPOINT.GET_CONTACT_PERSONS_DETAILS}/${bankId}/contact`,
            }),
            providesTags: ["get_contact_person_details"]
        }),

        /* GET CONTACT PERSON */
        getSingleContactPerson: builder.query({
            query: (personId) => ({
                url: `${ENDPOINT.GET_SINGLE_PERSON_DETAILS}/${personId}`,
            }),
            providesTags: ["get_single_contact_person_details"]
        }),

        /* PUT CONTACT PERSON */
        putContactPerson: builder.mutation({
            query: ({ contactId, payload }) => ({
                url: `${ENDPOINT.PUT_CONTACT_PERSON_DETAILS}/${contactId}`,
                body: payload,
                method: "PUT",
            }),
            invalidatesTags: ["get_contact_person_details"]
        }),

        /* DELETE CONTACT PERSON */
        deleteContactPerson: builder.mutation({
            query: (contactId) => ({
                url: `${ENDPOINT.DELETE_CONTACT_PERSON_DETAILS}/${contactId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["get_contact_person_details"]
        }),

        /* GET REQUESTED JOURNEY */
        getRequstedJourneys: builder.query({
            query: (bankId) => ({
                url: `${ENDPOINT.GET_REQUESTED_JOURNEY}/${bankId}`,
                method: "GET",
            }),
            providesTags: ["get_requested_journeys"]
        }),

        /* POST REQUESTED JOURNEY */
        postRequestJourney: builder.mutation({
            query: (data) => ({
                url: ENDPOINT.POST_REQUEST_JOURNEY,
                body: data,
                method: "POST",
            }),
            invalidatesTags: ["get_requested_journeys"],
        }),

        /* POST LENDING JOURNEY */
        postLendingJourney: builder.mutation({
            query: (data) => ({
                url: ENDPOINT.POST_LENDING_JOURNEY,
                body: data,
                method: "POST",
            }),
            invalidatesTags: ["get_approved_journeys", "get_saved_journeys"]
        }),

        /* GET ALL AVAILABLE JOURNEY */
        getApprovedJourneys: builder.query({
            query: () => ({
                url: `${ENDPOINT.GET_APPROVED_JOURNEY}`,
                method: "GET",
            }),
            providesTags: ["get_approved_journeys"]
        }),

        /* GET SAVED LENDING JOURNEY */
        getSavedLendingJourneys: builder.query({
            query: (bankId) => ({
                url: `${ENDPOINT.GET_SAVED_JOURNEY}/${bankId}`,
                method: "GET",
            }),
            providesTags: ["get_saved_journeys"]
        }),

        /* PUT LENDING JOURNEY DOCUMENT */
        putLendingJourneyDocument: builder.mutation({
            query: ({ journeyId, payload }) => ({
                url: `${ENDPOINT.PUT_LENDING_JOURNEY_DOCUMENT}/${journeyId}`,
                body: payload,
                method: "PUT",
            }),
            invalidatesTags: ["get_saved_journeys"]
        }),

        /* GET CATEGORY */
        getCategories: builder.query({
            query: () => ({
                url: ENDPOINT.GET_CATEGORIES,
                method: "GET",
            }),
            providesTags: ["get_categories"]
        }),

        /* GET SUBSCRIBED API */
        getSubscribedApi: builder.query({
            query: (bankId) => ({
                url: `${ENDPOINT.GET_SUBSCRIBED_API}/${bankId}/api`,
                method: "GET",
            }),
            providesTags: ["get_subscribed_apis"]
        }),

        /* POST API PACKAGES */
        postSubscribeApi: builder.mutation({
            query: (data) => ({
                url: ENDPOINT.POST_SUBSCRIBE_API,
                body: data,
                method: "POST",
            }),
            invalidatesTags: ["get_subscribed_apis"]
        }),

        /* PUT SUBSCRIBE API */
        putSubscribeApi: builder.mutation({
            query: ({ subId, payload }) => ({
                url: `${ENDPOINT.PUT_SUBSCRIBE_API}/${subId}`,
                body: payload,
                method: "PUT",
            }),
            invalidatesTags: ["get_subscribed_apis", "get_saved_journeys"]
        }),

        /* DELETE CONTACT PERSON */
        deleteSubscribedApi: builder.mutation({
            query: (subId) => ({
                url: `${ENDPOINT.DELETE_SUBSCRIBED_API}/${subId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["get_subscribed_apis"]
        }),

        /* GET ONBOARDING PROGRESS */
        getOnboardingProgress: builder.query({
            query: (bankId) => ({
                url: `${ENDPOINT.GET_PROGRESS}/${bankId}`,
                method: "GET",
            }),
        }),

    }),
})

export default onboardingApi;

export const {

    /* DESIGNATIONS */
    useLazyGetDesignationsQuery,

    /* DOWNLOAD */
    useLazyGetDownloadDocumentQuery,

    /* COMPANY */
    usePutCompanyDetailsMutation,
    useLazyGetCompanyDetailsQuery,

    /* CONTACT */
    usePostContactPersonMutation,
    usePutContactPersonMutation,
    useLazyGetContactPersonsQuery,
    useLazyGetSingleContactPersonQuery,
    useDeleteContactPersonMutation,

    /* JOURNEY REQUESTS */
    usePostRequestJourneyMutation,
    useLazyGetRequstedJourneysQuery,

    /* JOURNEY */
    usePostLendingJourneyMutation,
    useLazyGetApprovedJourneysQuery,
    useLazyGetSavedLendingJourneysQuery,
    usePutLendingJourneyDocumentMutation,

    /* SUBMIT */
    usePostSubmitFormMutation,

    /* CATEGORY */
    useLazyGetCategoriesQuery,
    usePostSubscribeApiMutation,
    usePutSubscribeApiMutation,
    useLazyGetSubscribedApiQuery,
    useDeleteSubscribedApiMutation,

    /* PROGRESS */
    useLazyGetOnboardingProgressQuery,

} = onboardingApi;

import validateUUID from "./validateUUID";

/* HELPER FUNCTION TO DETERMINE DISPLAY NAME OF FILE */
const showDocFileName = (value: string | File | null, fileName: string): string => {
    if (!value) {
        return "No file selected";
    }
    else if ((!(value instanceof File)) && validateUUID(value)) {
        return fileName;
    }
    else if (value instanceof File) {
        return (value as File).name;
    }
    else return fileName;
};

export default showDocFileName;
import { useState } from 'react';
import Modal from 'react-responsive-modal';
import { DownloadDocumentIcon } from '../../assets';
import { useDispatch } from 'react-redux';
import { showToast } from '../../redux/slices';
import { useLazyGetDownloadDocumentQuery } from '../../api';
import { docDownload, statusLabelBG } from '../../utils';
import { capitalizeFirstCharacter, validateUUID } from '../../helpers';
import Loader from '../loader/Loader';
import NoRecordsFound from '../no-records-found/NoRecordsFound';

interface IJourney {
    id: string;
    name: string;
    status: string;
    fileDocId: string;
    comment: string;
}

interface IModal {
    open: boolean;
    isLoading: boolean;
    closeModal: () => void;
    requestedJourneys: IJourney[];
}

const ViewRequestedJourneyModal = (props: IModal) => {
    const dispatch = useDispatch();
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const { open, closeModal, requestedJourneys, isLoading } = props;
    const minWidth = "40vw";
    const [getDocumentDownload] = useLazyGetDownloadDocumentQuery();

    const handleDownload = (id: string, fileName: string) => {
        if (!id) {
            dispatch(showToast({ message: "Document not uploaded yet.", type: 'error' }));
            return
        };

        /* CHECK VALID UUID */
        if (!validateUUID(id)) {
            dispatch(showToast({ message: "Invalid Document!", type: 'error' }));
            return;
        }
        setIsDownloadLoading(true)
        /* DOWNLOAD DOCUMENT */
        getDocumentDownload(id)
            .unwrap()
            .then((res) => {
                if (res) {
                    docDownload(res, fileName);
                    dispatch(showToast({ message: "File Downloaded Successfully.", type: 'success' }));
                    setIsDownloadLoading(false)
                }
            })
            .catch((error) => {
                dispatch(showToast({ message: error?.data?.message || "Failed", type: 'error' }));
                setIsDownloadLoading(false)
            });
    }

    return (
        <Modal center open={open} onClose={closeModal} onEscKeyDown={closeModal} showCloseIcon styles={{ modal: { minWidth } }} classNames={{ modal: "custom-modal" }}>
            {/* HEADER */}
            <div className="title-box">
                <h5>Requested Journey</h5>
            </div>

            {/* BODY */}
            <div className="onboarding-form">
                <div className="table-box">
                    <table className="table table-responsive common-table">
                        <thead>
                            <tr>
                                {["No.", "Name", "Status", "Document", "Comments"]?.map((data) => <th>{data}</th>)}
                            </tr>
                        </thead>

                        <tbody>
                            {isLoading
                                ?
                                <Loader height='300px' />
                                :
                                requestedJourneys?.length > 0 ? (
                                    requestedJourneys?.map((journey: IJourney, index: number) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{journey?.name}</td>
                                            <td className="status-bg-box">
                                                <span className={`status-bg-box ${statusLabelBG(journey?.status)}`}>{capitalizeFirstCharacter(journey?.status)}</span>
                                            </td>
                                            <td>
                                                {journey?.fileDocId
                                                    ? (<p className="btn light-btn m-0" onClick={() => handleDownload(journey?.fileDocId, `${journey?.name}.pdf`)}>
                                                        {isDownloadLoading && <div className="downloading-button-loader" />}
                                                        {!isDownloadLoading && <img src={DownloadDocumentIcon} alt="Download Document" />}
                                                        Download
                                                    </p>
                                                    )
                                                    : (<p className="pink-text">Not Uploaded!</p>)
                                                }
                                            </td>
                                            <td>{journey?.comment || "-"}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5}><NoRecordsFound height='300px' message='No journeys available!' /></td>
                                    </tr>
                                )}
                        </tbody>

                    </table>
                </div>
            </div>

            {/* FOOTER */}
            <div className="modal-btn-footer">
                <button type="button" className="line-btn btn" onClick={closeModal}>Close</button>
            </div>
        </Modal>
    );
};

export default ViewRequestedJourneyModal;

import { createApi } from '@reduxjs/toolkit/query/react'
import { ENDPOINT } from './endpoints';
import baseQuery from '../baseQuery';

export const authApi = createApi({
    reducerPath: 'authApi',

    baseQuery,

    endpoints: (builder) => ({

        postLogin: builder.mutation({
            query: (data) => ({
                url: ENDPOINT.LOGIN,
                body: data,
                method: "POST",
                headers: {
                    'Content-Type': `application/json`
                }
            }),
        }),

        putResetPassword: builder.mutation({
            query: (payload) => ({
                url: ENDPOINT.RESET_PASSWORD,
                body: payload,
                method: "POST",
                headers: {
                    'Content-Type': `application/json`
                }
            }),
        }),
    }),

})

export default authApi;

export const { usePostLoginMutation, usePutResetPasswordMutation } = authApi;

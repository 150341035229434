import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { PATHS } from '../../data';

export default function AuthLayout() {
    const token = useSelector((state: RootState) => state.auth.token);

    return token ? <Navigate to={PATHS.DASHBOARD} /> : <Outlet />;
}

import { CONTACT_PERSON_TYPES, USER_TYPES } from "../data";

const getDesignatedContactPersonFilterKey = (header: string) => {
    switch (header) {

        case CONTACT_PERSON_TYPES.BUSINESS:
            return USER_TYPES?.LE;

        case CONTACT_PERSON_TYPES.ESCALATION:
            return USER_TYPES?.ES;

        case CONTACT_PERSON_TYPES.IT:
            return USER_TYPES?.IT;

        default:
            return "";
    }
}

export default getDesignatedContactPersonFilterKey; 
import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINT } from './endpoints';
import baseQuery from '../baseQuery';

export const newsAndHelpApi = createApi({
    reducerPath: 'newsAndHelpApi',

    baseQuery,

    tagTypes: [
        "get_help",
        "get_newsfeed",
    ],

    endpoints: (builder) => ({

        /* GET NEWS FEED */
        getNewsfeed: builder.query({
            query: () => ({
                url: ENDPOINT.GET_NEWS_FEED,
                method: "GET",
            }),
        }),

        /* GET HELP */
        getHelp: builder.query({
            query: () => ({
                url: ENDPOINT.GET_HELP,
                method: "GET",
            }),
        }),
    }),
})

export default newsAndHelpApi;

export const {

    /* NEWS FEED */
    useLazyGetNewsfeedQuery,

    /* HELP */
    useLazyGetHelpQuery,

} = newsAndHelpApi;

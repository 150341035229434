import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard-icon.svg';
import { ReactComponent as IPWhitelistingIcon } from '../../assets/icons/ip-whitelisting.svg';
import { ReactComponent as OnboardingFormIcon } from '../../assets/icons/onboarding-form.svg';
import { ReactComponent as LendingJourneyIcon } from '../../assets/icons/lending-journey.svg';
import { ReactComponent as LegalAgreementIcon } from '../../assets/icons/legal-agreement.svg';
import { ReactComponent as ApiManagerIcon } from '../../assets/icons/api-manager.svg';
import { RBIHOnlyLogo, RBIULILogoIcon } from '../../assets';
import { usePathname } from '../../hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { PATHS } from '../../data';
import getNavItems from '../../utils/getNavItems';

interface IProps {
    isSidebarCollapsed: boolean;
}

const Sidebar: FC<IProps> = ({ isSidebarCollapsed }) => {
    const pathname = usePathname();
    const userType = useSelector((state: RootState) => state.auth.user?.usertype);
    const status = useSelector((state: RootState) => state.auth.bank?.status);

    const navItems = getNavItems(userType, status).map(item => ({
        ...item,
        icon: (() => {
            switch (item.path) {
                case PATHS.DASHBOARD:
                    return <DashboardIcon />;
                case PATHS.ONBOARDING_FORM:
                    return <OnboardingFormIcon />;
                case PATHS.LEGAL_AGREEMENT:
                    return <LegalAgreementIcon />;
                case PATHS.IP_WHITELISTING:
                    return <IPWhitelistingIcon />;
                case PATHS.API_MANAGER:
                    return <ApiManagerIcon />;
                case PATHS.LENDING_JOURNEY:
                    return <LendingJourneyIcon />;
                default:
                    return null;
            }
        })(),
    }));

    return (
        <div className="custom-side-menu">
            <div className="left-sidebar">
                <div className="logo-area display-flex justify-content-sb">
                    <Link to={PATHS.DASHBOARD} className={isSidebarCollapsed ? 'rbih-logo-icon' : 'rbih-full-logo'}>
                        <img src={isSidebarCollapsed ? RBIHOnlyLogo : RBIULILogoIcon} alt="logo" />
                    </Link>
                </div>
                <div className="sidenav">
                    <aside id="side-menu">
                        <ul className="nav nav-list accordion">
                            {navItems.map(item => (
                                <li className={`nav-header ${pathname === item.path ? 'active' : ''}`} key={item.path}>
                                    <Link to={item.path} title={item.title}>
                                        {item.icon}
                                        {item.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </aside>
                </div>
                {/* <div style={{ position: 'absolute', bottom: '20px', width: '100%', textAlign: 'center' }}>
                    <h5 style={{ color: '#6D6E71', margin: '0', padding: '5px', borderRadius: '3px', fontSize: '12px' }}>
                        Powered by
                    </h5>
                    <div className="small-logo">
                        <img src={RBIHLogoWithTextIcon} alt="RBIH Logo" style={{ width: '50px', height: 'auto' }} />
                    </div>
                </div> */}
                {/* {!isSidebarCollapsed &&
                    <div className="powered-by-container">
                        <p>Powered by</p>
                        <div className="small-logo">
                            <img src={RBIHLogoWithTextIcon} alt="RBIH Logo" style={{ width: '125px', height: 'auto' }} />
                        </div>
                    </div>
                } */}
            </div>
        </div >
    );
};

export default Sidebar;

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { Header, Sidebar } from '../../components';
import { RootState } from '../../redux/store';
import { PATHS } from '../../data';

export default function DashboardLayout() {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)
    const token = useSelector((state: RootState) => state.auth.token);

    if (!token) {
        return <Navigate to={PATHS.LOGIN} />;
    }

    const updateSidebarCollapse = () => setIsSidebarCollapsed((prev: boolean) => !prev)

    return (
        <div className={isSidebarCollapsed ? 'aside-menu-collage' : ""}>
            <div className="wrapper">
                <div className="main-section">

                    {/* HEADER */}
                    <Header
                        updateSidebarCollapse={updateSidebarCollapse}
                    />

                    {/* SIDEBAR */}
                    <Sidebar
                        isSidebarCollapsed={isSidebarCollapsed}
                    />

                    <div className="inner-wrapper">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>

    )
}

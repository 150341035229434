import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteContactPersonMutation } from "../../api";
import { showToast } from "../../redux/slices";
import { RootState } from "../../redux/store";
import { ACTION_TYPES, STATUS_TYPES } from "../../data";

interface IModal {
    open: boolean;
    actionType: string
    closeModal: () => void;
    personId: string | null;
}

const DeleteDesignatedContactPersonModal = (props: IModal) => {
    const dispatch = useDispatch()
    const { open, closeModal, personId, actionType } = props;
    const [deleteContactPerson, { isLoading }] = useDeleteContactPersonMutation();
    const bank = useSelector((state: RootState) => state.auth.bank);
    const isFormApproved = bank?.status === STATUS_TYPES.APPROVED;
    const isDeleteType = actionType === ACTION_TYPES.DELETE;
    const minWidth = "40vw";

    const handleClose = () => {
        closeModal();
    };

    const handleConfirm = () => {
        if (open && isDeleteType) {
            if (!personId) {
                dispatch(showToast({ message: "Invalid Contact Person!", type: 'error' }));
                return;
            };

            deleteContactPerson(personId)
                .unwrap()
                .then((res) => {
                    handleClose();
                    dispatch(showToast({ message: res?.message || "Success", type: 'success' }))
                })
                .catch((error) => dispatch(showToast({ message: error?.data?.message || "Failed", type: 'error' })));
        }
    };

    return (
        <Modal
            center
            open={open}
            onClose={handleClose}
            onEscKeyDown={handleClose}
            showCloseIcon
            styles={{ modal: { minWidth } }}
            classNames={{ modal: "custom-modal" }}
        >

            {/* HEADER */}
            <div className="title-box">
                <h5>Delete Designated Contact Person</h5>
            </div>

            {/* BODY */}
            <div className="row">
                <div className="col-12">
                    <p className="final-text">Are you sure you want to delete?</p>
                </div>
            </div>

            {/* FOOTER */}
            <div className="modal-btn-footer">
                <button type="button" disabled={isLoading} className="line-btn btn" title="Cancel" onClick={closeModal}>Cancel</button>
                <button type="button" disabled={isLoading || isFormApproved} title="Yes, Delete" className="btn common-btn" onClick={() => handleConfirm()}>
                    {isLoading && <div className="submit-button-loader" />}
                    Yes, Delete
                </button>
            </div>

        </Modal>
    );
};

export default DeleteDesignatedContactPersonModal;

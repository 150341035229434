import { formatDateToDDMMMYYYY } from "../../helpers";
import Loader from "../loader/Loader";
import NoRecordsFound from "../no-records-found/NoRecordsFound";

interface IProps {
    isLoading: boolean;
    savedJourneys: ISavedJourney[];
}

interface ISavedJourney {
    journey: {
        name: string;
        id: string;
    }
    tentetiveDate: string;
}

const LendingJourneySection = (props: IProps) => {
    const { savedJourneys, isLoading } = props;

    return (
        <div className="common-data-box">
            <h6 className="data-title-box">
                Lending Journeys
            </h6>

            {isLoading ? (
                <Loader height="100px" />
            ) : savedJourneys?.length <= 0 ? (
                <NoRecordsFound message="No Lending Journey Found!" height="100px" />
            ) : (
                savedJourneys && savedJourneys?.length > 0 && savedJourneys?.map((jr: ISavedJourney, index: number) =>
                (index % 2 === 0 &&
                    (
                        <div className="data-box" key={index}>
                            <div className="left-box">
                                <span>{jr.journey.name}</span>
                                <p>{formatDateToDDMMMYYYY(jr.tentetiveDate)}</p>
                            </div>
                            {
                                savedJourneys[index + 1] &&
                                (
                                    <div className="right-box">
                                        <span>{savedJourneys[index + 1]?.journey?.name}</span>
                                        <p>{formatDateToDDMMMYYYY(savedJourneys[index + 1]?.tentetiveDate)}</p>
                                    </div>
                                )
                            }
                        </div>
                    )
                ))
            )}
        </div>
    );
};

export default LendingJourneySection;

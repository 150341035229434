import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../redux/slices';
import { RootState } from '../../redux/store';
import { usePostRequestJourneyMutation } from '../../api';
import { reqJourneyValSchema } from '../../validations';
import { note, STATUS_TYPES } from '../../data';
import FileUpload from '../file-upload/FileUpload';
import Modal from 'react-responsive-modal';

interface IModal {
    open: boolean
    closeModal: () => void;
    minWidth?: string
}

const RequestJourneyModal = (props: IModal) => {
    const { open, closeModal, minWidth = "40vw" } = props;
    const dispatch = useDispatch();
    const [postLJourney, { isLoading }] = usePostRequestJourneyMutation();
    const bank = useSelector((state: RootState) => state.auth.bank);
    const isFormApproved = bank?.status === STATUS_TYPES.APPROVED;

    /* CLOSE MODAL */
    const handleClose = () => {
        formik.resetForm();
        closeModal()
    }

    const formik = useFormik({
        initialValues: { name: '', fileDoc: null, bankId: bank?.id },
        validationSchema: reqJourneyValSchema,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (values) => {

            const formData = new FormData();
            if (values?.fileDoc) {
                formData.append(`fileDoc`, values?.fileDoc as File);
            }
            formData.append("name", values?.name);
            formData.append("bankId", bank?.id);

            postLJourney(formData)
                .unwrap()
                .then((res) => {
                    dispatch(showToast({ message: res?.message || "Success!", type: 'success' }));
                    handleClose();
                })
                .catch((error) => dispatch(showToast({ message: error?.data?.message || "Failed", type: 'error' })));
        }
    });



    return (

        <Modal
            center
            open={open}
            onClose={() => handleClose()}
            onEscKeyDown={() => handleClose()}
            showCloseIcon
            styles={{ modal: { minWidth } }}
            classNames={{ modal: "custom-modal" }}
        >

            <form onSubmit={formik.handleSubmit}>
                <div className="title-box">
                    <h5>Request for New Lending Journey</h5>
                </div>

                <div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label className="form-label">Request Journey Name<span className="required-dot">*</span></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={"name"}
                                    value={formik.values?.name}
                                    placeholder="Enter your journey"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    disabled={isFormApproved}
                                />
                                {formik.touched?.name && formik.errors.name ? (
                                    <div className="error-msg">{formik.errors.name}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor={`fileDoc`} className="form-label">Attach Journey Document</label>
                                <FileUpload
                                    fieldName='fileDoc'
                                    formik={formik}
                                    fileName="Journey.pdf"
                                    isDisabled={isFormApproved}
                                />
                                {formik.touched?.fileDoc && formik.errors.fileDoc ? (
                                    <div className="error-msg">{formik.errors.fileDoc}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <p className="note">{note}</p>
                    {/* FOOTER */}
                    <div className="main-btn-group pager">
                        <button type="button" className="line-btn btn" onClick={() => handleClose()}>Cancel</button>
                        <button type="submit" disabled={isLoading || isFormApproved} className="btn common-btn">
                            {isLoading && <div className="submit-button-loader" />}
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default RequestJourneyModal;

import { createSlice } from '@reduxjs/toolkit';
import { authApi, onboardingApi } from '../../api';
import { convertBase64ToDataUrl } from '../../helpers';
import { STATUS_TYPES } from '../../data';

interface IBank {
    id: string;
    logo: string | null;
    bankName: string;
    gst: string;
    empCertDocId: string | null;
    empCode: string;
    status: string;
    markCompleted: boolean | null;
    stage: number;
}
interface IContactPerson {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    designation?: {
        id: number
        name: string
    };
    usertype: string;
    contactLvl: number;
}
interface IInitialState {
    token: string | null;
    error: string | null;
    user: IContactPerson;
    bank: IBank;
}

const initialState: IInitialState = {
    token: null,
    error: null,
    user: {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        designation: {
            id: 0,
            name: ""
        },
        contactLvl: 0,
        usertype: "",
    },
    bank: {
        id: "",
        bankName: "",
        gst: "",
        logo: null,
        empCertDocId: null,
        empCode: "",
        status: STATUS_TYPES.PENDING,
        stage: 0,
        markCompleted: false,
    },
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: () => ({ ...initialState })
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                authApi.endpoints.postLogin.matchFulfilled,
                (state, action) => {
                    const token = action.payload.token;

                    /* SETTING UP USER */
                    const user = {
                        firstName: action.payload.body?.firstName || "",
                        lastName: action.payload.body?.lastName || "",
                        email: action.payload.body?.email || "",
                        mobile: action.payload.body?.mobile || "",
                        designation: action.payload.body?.designation,
                        usertype: action.payload.body?.usertype,
                        contactLvl: action.payload.body?.contactLvl || 0,
                    };

                    /* SETTING UP BANK */
                    const bank = {
                        id: action.payload.body?.bank?.id || "",
                        logo: action.payload.body?.bank?.logo ? convertBase64ToDataUrl(action.payload.body?.bank?.logo) : null,
                        bankName: action.payload.body?.bank?.bankName || "",
                        gst: action.payload.body?.bank?.gst || "",
                        empCertDocId: action.payload.body?.bank?.empCertDocId || null,
                        empCode: action.payload.body?.bank?.empCode || "",
                        status: action.payload.body?.bank?.status || STATUS_TYPES.PENDING,
                        stage: action.payload.body?.bank?.stage,
                        markCompleted: action.payload.body?.bank?.markCompleted || false,
                    };

                    return {
                        ...state,
                        token,
                        user,
                        error: null,
                        bank,
                    };
                }
            )
            .addMatcher(onboardingApi.endpoints.getCompanyDetails.matchFulfilled, (state, action) => {
                const bank = {
                    id: action.payload.body?.id || "",
                    logo: action.payload.body?.logo ? convertBase64ToDataUrl(action.payload.body?.logo) : null,
                    bankName: action.payload.body?.bankName || "",
                    gst: action.payload.body?.gst || "",
                    empCertDocId: action.payload.body?.empCertDocId || null,
                    empCode: action.payload.body?.empCode || "",
                    status: action.payload.body?.status || STATUS_TYPES.PENDING,
                    stage: action.payload.body?.stage || 0,
                    markCompleted: action.payload.body?.markCompleted || false,
                };
                return {
                    ...state,
                    bank,
                }
            })
            .addMatcher(onboardingApi.endpoints.getContactPersons.matchFulfilled, (state, action) => {
                const userDetails = action?.payload?.body?.find((cp: IContactPerson) => cp?.email?.toLocaleLowerCase() === state?.user?.email?.toLocaleLowerCase());
                const user = {
                    firstName: userDetails?.firstName || "",
                    lastName: userDetails?.lastName || "",
                    email: userDetails?.email,
                    mobile: userDetails?.mobile || "",
                    designation: userDetails?.designation,
                    usertype: userDetails?.usertype,
                    contactLvl: userDetails?.contactLvl || 0,
                };

                return {
                    ...state,
                    user,
                }
            })
    },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;

import { CONTACT_PERSON_TYPES, USER_TYPES } from "../../data";
import { getDesignatedContactPersonFilterKey } from "../../helpers";
import DocumentPreview from "../document-preview/DocumentPreview";
import Loader from "../loader/Loader";
import NoRecordsFound from "../no-records-found/NoRecordsFound";

interface IContactPerson {
    id: string;
    firstName: string;
    lastName: string;
    designation: {
        name: string;
        id: number;
    };
    department: string;
    email: string;
    mobile: string;
    idProofType: string;
    idProofNo: string;
    usertype: string;
    idProofDoc: File | null;
    bankId: string;
    idProofDocId: string;
}

interface IProps {
    header: string;
    isLoading: boolean;
    contactPersons: IContactPerson[];
}

const DesignatedContactPerson = ({ contactPersons, isLoading, header }: IProps) => {
    const filterKey = getDesignatedContactPersonFilterKey(header);

    // Filter the contact persons once based on the header
    const filteredContacts = contactPersons?.filter(person => person?.usertype === filterKey) || [];

    // Determine if there is data
    const hasData = filteredContacts?.length > 0;

    // Get title based on the user type
    const getTitle = (usertype: string, index: number) => {
        switch (usertype) {
            case USER_TYPES.IT:
                return `ID Proof (${CONTACT_PERSON_TYPES.IT} ${index + 1})`;
            case USER_TYPES.ES:
                return `ID Proof (${CONTACT_PERSON_TYPES.ESCALATION} ${index + 1})`;
            case USER_TYPES.LE:
                return `ID Proof (${CONTACT_PERSON_TYPES.BUSINESS} ${index + 1})`;
            default:
                return `ID Proof (${index + 1})`;
        }
    };

    // Loader for when data is still loading
    if (isLoading) {
        return (
            <div className="common-data-box">
                <h6 className="data-title-box">
                    Designated Contact Person ({header})
                </h6>
                <Loader height="100px" />
            </div>
        );
    }

    // Display no data found when filtered list is empty
    if (!hasData) {
        return (
            <div className="common-data-box">
                <h6 className="data-title-box">
                    Designated Contact Person ({header})
                </h6>
                <NoRecordsFound message={`No ${header} Persons Found!`} height="100px" />
            </div>
        );
    }

    // Main content rendering when data is available
    return (
        <div className="common-data-box">
            {filteredContacts?.map((contact, index) => (
                <div key={contact.id}>
                    <h6 className="data-title-box">
                        Designated Contact Person ({header} {index + 1})
                    </h6>
                    <div className="data-box">
                        <div className="left-box">
                            <span>First Name</span>
                            <p>{contact?.firstName}</p>
                        </div>
                        <div className="right-box">
                            <span>Last Name</span>
                            <p>{contact?.lastName}</p>
                        </div>
                    </div>
                    <div className="data-box">
                        <div className="left-box">
                            <span>Designation</span>
                            <p>{contact?.designation?.name}</p>
                        </div>
                        <div className="right-box">
                            <span>Business Unit</span>
                            <p>{contact?.department}</p>
                        </div>
                    </div>
                    <div className="data-box">
                        <div className="left-box">
                            <span>Email ID</span>
                            <p>{contact?.email}</p>
                        </div>
                        <div className="right-box">
                            <span>Mobile No.</span>
                            <p>{contact?.mobile}</p>
                        </div>
                    </div>
                    <div className="data-box">
                        <div className="left-box">
                            <span>ID Proof Type</span>
                            <p>{contact?.idProofType}</p>
                        </div>
                        <div className="right-box">
                            <span>ID Number</span>
                            <p>{contact?.idProofNo}</p>
                        </div>
                    </div>
                    <div className="document-data-box">
                        <div className="common-block-box company-details-documents mb-3">
                            <DocumentPreview
                                title={getTitle(contact.usertype, index)}
                                id={contact.idProofDocId}
                                fileName={`${getTitle(contact?.usertype, index)}.pdf`}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DesignatedContactPerson;
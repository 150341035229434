import { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetApprovedJourneysQuery, useLazyGetRequstedJourneysQuery, useLazyGetSavedLendingJourneysQuery, usePostLendingJourneyMutation } from "../../api";
import { AddIcon, EyeIcon, NextIcon, PreviousIcon, SearchIcon, UploadLendingJourneyIcon } from "../../assets";
import { RequestJourneyModal, ViewRequestedJourneyModal } from "../modals";
import { RootState } from "../../redux/store";
import { formatDateToYYYYMMDD } from "../../helpers";
import { showToast } from "../../redux/slices";
import { STATUS_TYPES } from "../../data";
import Loader from "../loader/Loader";
import NoRecordsFound from "../no-records-found/NoRecordsFound";

interface IProps {
    handleNextStep: () => void;
    handlePreviousStep: () => void;
}

interface IJourney {
    name: string;
    id: string;
    journey: {
        id: string;
    }
}

interface IRequestedJourneyResponse {
    body: {
        status: string
    }[]
}
interface IRequestedJourney {
    status: string
}

interface IPayload {
    id?: string | null
    bankId: string | null;
    journeyId: string | null;
    tentetiveDate: string | null;
    name: string | null;
    journey?: {
        id?: string;
        name: string;
    }
}

const LendingJourneyForm = ({ handleNextStep, handlePreviousStep }: IProps) => {
    const dispatch = useDispatch();
    const [requestModalOpen, setRequestModalOpen] = useState<boolean>(false);
    const [viewModalOpen, setViewModalOpen] = useState<boolean>(false);
    const [isRequestedJourniesPending, setIsRequestedJourniesPending] = useState<boolean>(false);
    const [journeys, setJourneys] = useState<IPayload[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [getApprovedJourneys, { data: approvedJourneys, isLoading }] = useLazyGetApprovedJourneysQuery();
    const [getSavedJourneys, { isLoading: savedJourneyIsLoading }] = useLazyGetSavedLendingJourneysQuery();
    const [putLendingJourneys, { isLoading: postLendingLoading }] = usePostLendingJourneyMutation();
    const [getRequestedJourneys, { data: requestedJourneys, isLoading: getRequestedJourneyLoading }] = useLazyGetRequstedJourneysQuery();
    const bank = useSelector((state: RootState) => state.auth.bank);
    const isFormApproved = bank?.status === STATUS_TYPES.APPROVED;
    const headers = ["Select", "Available Journeys", "Tentative Go Live Date"];

    const openViewModal = () => setViewModalOpen(true);
    const closeViewModal = () => setViewModalOpen(false);
    const openRequestModal = () => setRequestModalOpen(true);
    const closeRequestModal = () => setRequestModalOpen(false);

    const handleCheckboxChange = (journey: IJourney) => {
        setJourneys((prevJourneys: IPayload[]) => {
            const isSelected = prevJourneys?.some((j) => j?.journeyId === journey?.id);

            if (isSelected) {
                return prevJourneys?.filter((j) => j.journeyId !== journey?.id);
            } else {
                const tentativeDate = journeys.find(j => j.journeyId === journey?.id)?.tentetiveDate || null;
                return [...prevJourneys, { journeyId: journey?.id, tentetiveDate: tentativeDate, bankId: bank?.id, name: journey?.name }];
            }
        });
    };

    const handleDateChange = (journeyId: string, tentetiveDate: string) => {
        setJourneys(prevJourneys =>
            prevJourneys?.map(journey =>
                journey?.journeyId === journeyId ? { ...journey, tentetiveDate } : journey
            )
        );
    };

    const validateJourneys = (): boolean => {
        if (journeys?.length <= 0) {
            dispatch(showToast({ message: "At least one journey must be selected", type: 'error' }));
            return false;
        }

        for (const journey of journeys) {
            if (!journey?.journeyId) {
                dispatch(showToast({ message: "Journey ID must not be empty", type: 'error' }));
                return false;
            }
            if (!journey?.tentetiveDate) {
                dispatch(showToast({ message: `${journey?.name}'s tentative date is required`, type: 'error' }));
                return false;
            }
        }
        return true;
    };

    /* HANDLE SUBMIT */
    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        /* IF FORM APPROVED THEN SKIPS OTHER THINGS */
        if (isFormApproved) {
            handleNextStep();
            return;
        }

        /* IF ANY REQUESTED JOURNEY IS PENDING */
        if (isRequestedJourniesPending) {
            dispatch(showToast({ message: "Some of your requested journies are pending. Once they will be approved or rejected, you can submit the form.", type: 'error' }))
            return
        };

        /* VALIDATIONS */
        if (!validateJourneys()) {
            return;
        }

        /* FILTER PAYLOAD */
        const payload = journeys
            .filter(journey => journey?.journeyId && journey?.tentetiveDate)
            .map(journey => ({
                bankId: bank?.id,
                journeyId: journey?.journeyId!,
                tentetiveDate: formatDateToYYYYMMDD(journey.tentetiveDate!),
            }));

        /* API CALL */
        putLendingJourneys(payload)
            .unwrap()
            .then((res) => {
                dispatch(showToast({ message: res?.message || "Success!", type: 'success' }));
                /* HANDLE NEXT */
                handleNextStep();
            })
            .catch((error) => dispatch(showToast({ message: error?.data?.message || "Failed", type: 'error' })));
    };

    /* HANDLE SEARCH */
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    /* HANDLE FILTER */
    const filteredJourneys = approvedJourneys && approvedJourneys?.body?.length > 0 ? approvedJourneys?.body.filter((journey: IJourney) => journey?.name?.toLowerCase()?.includes(searchQuery.toLowerCase())) : [];

    /* API CALLS */
    useEffect(() => {

        /* GET APPROVED JOURNEYS */
        getApprovedJourneys({})
            .unwrap()
            .then((approvedResponse) => {
                getSavedJourneys(bank?.id)
                    .unwrap()
                    .then((savedResponse) => {
                        const savedJourneys = savedResponse?.body || [];
                        const approvedJourneys = approvedResponse?.body || [];

                        const matchingJourneys = savedJourneys
                            .filter((savedJourney: IJourney) =>
                                approvedJourneys?.some((approvedJourney: IJourney) => approvedJourney?.id === savedJourney?.journey?.id)
                            )
                            .map((jr: IPayload) => ({
                                journeyId: jr?.journey?.id as string,
                                tentetiveDate: jr?.tentetiveDate || null,
                                bankId: bank?.id,
                                name: jr?.journey?.name
                            }));

                        setJourneys(matchingJourneys);
                    })
                    .catch((error) => dispatch(showToast({ message: error?.data?.message, type: 'error' })));
            })
            .catch((error) => dispatch(showToast({ message: error?.data?.message, type: 'error' })));

        /* GET REQUESTED JOURNEYS */
        getRequestedJourneys(bank?.id)
            .unwrap()
            .then((res: IRequestedJourneyResponse) => {
                if (res?.body?.length > 0) {
                    const anyPendingJourney = res?.body?.some(({ status }: IRequestedJourney) => status === STATUS_TYPES.PENDING)
                    setIsRequestedJourniesPending(anyPendingJourney);
                }
            })
            .catch((error) => dispatch(showToast({ message: error?.data?.message ?? "Error!", type: 'error' })));
    }, [bank?.id, requestedJourneys, dispatch, getApprovedJourneys, getRequestedJourneys, getSavedJourneys]);

    return (
        <>
            <div className="panel panel-primary setup-content" id="step-5">
                <div className="onboarding-form">
                    <div className="company-details-form common-p-20 bg-white-border">
                        <div className="title-box">
                            <div className="icon-box">
                                <img src={UploadLendingJourneyIcon} alt="upload-lending-journey" />
                            </div>
                            <h4>Lending Journeys</h4>
                        </div>

                        <div className="add-form-btn">
                            {/* ADD BUTTON */}
                            <button type="button" className="light-btn btn" onClick={() => openRequestModal()}>
                                <img src={AddIcon} alt='add request' />
                                Request For New Lending Journey
                            </button>
                            <button type="button" className="light-btn btn" onClick={() => openViewModal()}>
                                <img src={EyeIcon} alt='add request' />
                                View Requested Journey
                            </button>
                        </div>

                        {/* SEARCH BOX */}
                        <div className="main-search-box common-flex">
                            <div className="top-search-box common-flex">
                                <div className="form-group">
                                    <img src={SearchIcon} alt="search" />
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="table-box">
                            <form onSubmit={handleSubmit}>
                                <table className="table table-responsive common-table">
                                    <thead>
                                        <tr>
                                            {headers.map((header, index) => <th key={index}>{header}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading || savedJourneyIsLoading ? (
                                            <tr>
                                                <td colSpan={headers.length}><Loader /></td>
                                            </tr>
                                        ) : !filteredJourneys || filteredJourneys?.length <= 0 ? (
                                            <tr>
                                                <td colSpan={headers.length}><NoRecordsFound message="No Journeys Available!" /></td>
                                            </tr>
                                        ) : (
                                            filteredJourneys.map((journey: IJourney, index: number) => {
                                                const isSelected = journeys.some(j => j.journeyId === journey.id);
                                                const selectedJourney = journeys.find(j => j.journeyId === journey.id);
                                                const tentetiveDate = selectedJourney?.tentetiveDate || "";
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                checked={isSelected}
                                                                onChange={() => handleCheckboxChange(journey)}
                                                                disabled={isFormApproved}
                                                            />
                                                        </td>
                                                        <td>{journey?.name}</td>
                                                        <td>
                                                            <div className="calendar">
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    disabled={!isSelected || isFormApproved}
                                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleDateChange(journey.id, e.target.value)}
                                                                    /* Extract date part from the datetime string */
                                                                    value={tentetiveDate?.split('T')[0]}
                                                                    min={new Date()?.toISOString()?.split('T')[0]}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                                <ul className="main-btn-group pager">
                                    {/* SUBMIT BUTTON */}
                                    <li>
                                        <button type='submit' disabled={postLendingLoading || isFormApproved} className="common-btn btn">
                                            {postLendingLoading && <div className="submit-button-loader" />}
                                            Save
                                        </button>
                                    </li>

                                    {/* PREV BUTTON */}
                                    <li className="prev-btn-wrapper" onClick={() => handlePreviousStep()}>
                                        <button type="button" className="line-btn btn prevBtn">
                                            <img src={PreviousIcon} alt="prev icon" className="prev-icon" />
                                            Previous
                                        </button>
                                    </li>

                                    {/* NEXT BUTTON */}
                                    <li className="next-btn-wrapper">
                                        <button type="submit" className="line-btn btn nextBtn">
                                            Next
                                            <img src={NextIcon} alt="next icon" className="next-icon" />
                                        </button>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* REQUEST JOURNEY MODAL */}
            <RequestJourneyModal
                open={requestModalOpen}
                closeModal={closeRequestModal}
            />

            {/* VIEW ALL REQUESTED JOURNEY MODAL */}
            <ViewRequestedJourneyModal
                open={viewModalOpen}
                closeModal={closeViewModal}
                isLoading={getRequestedJourneyLoading}
                requestedJourneys={requestedJourneys?.body}
            />
        </>
    );
};

export default LendingJourneyForm;
import { createApi } from '@reduxjs/toolkit/query/react'
import { ENDPOINT } from './endpoints';
import baseQuery from '../baseQuery';

export const legalAgreementApi = createApi({
    reducerPath: 'legalAgreementApi',

    baseQuery,

    tagTypes: [
        "get_legal_agreement",
    ],

    endpoints: (builder) => ({

        /* POST LEGAL AGREEMENT */
        postLegalAgreement: builder.mutation({
            query: ({ bankId, payload }) => ({
                url: `${ENDPOINT.POST_LEGAL_AGREEMENT}/${bankId}/agreement`,
                body: payload,
                method: "POST",
            }),
            invalidatesTags: ["get_legal_agreement"]
        }),

        /* GET LEGAL AGREEMENT*/
        getLegalAgreement: builder.query({
            query: (bankId) => ({
                url: `${ENDPOINT.GET_LEGAL_AGREEMENT}/${bankId}/agreement`,
            }),
            providesTags: ["get_legal_agreement"]
        }),

    }),

})

export default legalAgreementApi;

export const {

    useLazyGetLegalAgreementQuery,
    usePostLegalAgreementMutation,

} = legalAgreementApi;

import './loading-fallback.css';

const LoadingFallback = () => {
    return (
        <div className="loading-fallback-container">
            <div className="loading-fallback" />
        </div>
    );
};

export default LoadingFallback;

import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { CompanyDetailsFormIcon, NextIcon } from '../../assets';
import { companyDetailsValSchema } from '../../validations';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../redux/slices';
import { useLazyGetCompanyDetailsQuery, usePutCompanyDetailsMutation } from '../../api';
import { RootState } from '../../redux/store';
import { convertbase64ToBlobToFile } from '../../helpers';
import { FILE_TYPES, note, STATUS_TYPES } from '../../data';
import Loader from '../loader/Loader';
import FileUpload from '../file-upload/FileUpload';

interface IProps {
    handleNextStep: () => void;
    handlePreviousStep: () => void;
}

interface ICompanyDetailsForm {
    bankName: string;
    cin: string;
    cinDocId: File | string | null;
    gst: string;
    gstDocId: File | string | null;
    pan: string;
    panDocId: File | string | null;
    logo: File | string | null;
    empCode: string;
    empCertDocId: File | string | null;
}

const DEFAULT_VALUES: ICompanyDetailsForm = {
    bankName: '',
    cin: '',
    cinDocId: null,
    gst: '',
    gstDocId: null,
    pan: '',
    panDocId: null,
    logo: null,
    empCode: '',
    empCertDocId: null,
};

const CompanyDetailsForm: FC<IProps> = ({ handleNextStep }) => {
    const dispatch = useDispatch();
    const [getCompanyDetails, { data, isLoading }] = useLazyGetCompanyDetailsQuery();
    const [putCompanyDetails, { isLoading: isPutLoading }] = usePutCompanyDetailsMutation();
    const bank = useSelector((state: RootState) => state.auth.bank);
    const isFormApproved = bank?.status === STATUS_TYPES.APPROVED;
    const [initialValues, setInitialValues] = useState<ICompanyDetailsForm>(DEFAULT_VALUES);

    const convertToFormData = (data: ICompanyDetailsForm): FormData => {
        const formData = new FormData();
        const fieldMapping: { [key: string]: string } = {
            gstDocId: 'gstDoc',
            cinDocId: 'cinDoc',
            panDocId: 'panDoc',
            empCertDocId: 'empCertDoc',
        };

        Object.keys(data).forEach(key => {
            const value = data[key as keyof ICompanyDetailsForm];
            let formKey = key;

            /* IF LOGO IS BASE64 THEN CONVERT TO FILE */
            if (value && formKey === "logo" && !(value instanceof File)) {
                const file = convertbase64ToBlobToFile(value, "logo.png", FILE_TYPES.PNG);
                formData.append(formKey, file as File);
                return
            }

            /* CHECK MAPPING FOR FILEs */
            if (value instanceof File && fieldMapping[key]) {
                formKey = fieldMapping[key];
            }

            if (value instanceof File) {
                /* APPENDING FILES */
                formData.append(formKey, value);
            } else {
                /* APPENDING STRINGS */
                formData.append(formKey, value as string);
            }
        });

        return formData;
    };

    /* FORMIK SETUP */
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: companyDetailsValSchema,
        onSubmit: values => {
            /* IF FORM APPROVED THEN SKIPS API CALL */
            if (isFormApproved) {
                handleNextStep();
                return;
            }
            /* CREATING PAYLOAD */
            const payload = convertToFormData(values);

            /* API CALL */
            putCompanyDetails({ bankId: bank?.id, payload })
                .unwrap()
                .then((res) => {
                    dispatch(showToast({ message: res?.message || 'Success!', type: 'success' }));
                    handleNextStep();
                })
                .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Failed', type: 'error' })));
        },
    });

    useEffect(() => {
        if (!bank?.id?.trim()) {
            return;
        }

        getCompanyDetails(bank?.id)
            .unwrap()
            .then((res) => {
                const bankDetails = res?.body;
                setInitialValues({
                    bankName: bankDetails?.bankName || '',
                    cin: bankDetails?.cin || '',
                    cinDocId: bankDetails?.cinDocId || null,
                    gst: bankDetails?.gst || '',
                    gstDocId: bankDetails?.gstDocId || null,
                    pan: bankDetails?.pan || '',
                    panDocId: bankDetails?.panDocId || null,
                    logo: bankDetails?.logo || null,
                    empCode: bankDetails?.empCode || '',
                    empCertDocId: bankDetails?.empCertDocId || null,
                });
            })
            .catch((error) => {
                /* RTK HANDLES 401 and 403 ERRORS! */
                if (error?.status === 401 || error?.status === 403) {
                    return;
                }
                dispatch(showToast({ message: error?.data?.message || 'Internal Server Error! Please try again later.', type: 'error' }));
            });
    }, [getCompanyDetails, data, bank?.id, dispatch]);

    return (
        <div className="panel panel-primary setup-content" id="step-1">
            <div className="onboarding-form">
                <div className="company-details-form common-p-20 bg-white-border">
                    <div className="title-box">
                        <div className="icon-box">
                            <img src={CompanyDetailsFormIcon} alt="api-packages" />
                        </div>
                        <h4>Company Details</h4>
                    </div>
                    {isLoading
                        ? <Loader />
                        :
                        <form onSubmit={formik.handleSubmit}>
                            <div className="column-4">
                                <div className="form-group">
                                    <label className="form-label">Organization Name <span className="required-dot">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={formik?.values?.bankName}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Company Incorporation Number<span className="required-dot">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter company incorporation number"
                                        name="cin"
                                        onChange={e => formik.setFieldValue('cin', e?.target?.value?.toUpperCase())}
                                        onBlur={formik?.handleBlur}
                                        value={formik?.values?.cin?.toUpperCase()}
                                        disabled={isFormApproved}
                                    />
                                    {formik?.touched.cin && formik?.errors.cin ? (
                                        <div className="error-msg">{formik?.errors.cin}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="cinDocId" className="form-label">
                                        Attach Company Incorporation Certificate<span className="required-dot">*</span>
                                    </label>
                                    <FileUpload
                                        isDisabled={isFormApproved}
                                        fieldName='cinDocId'
                                        formik={formik}
                                        fileName="Company Incorporation Certificate.pdf"
                                    />
                                    {formik.touched.cinDocId && formik.errors.cinDocId ? (
                                        <div className="error-msg">{formik.errors.cinDocId}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="column-4">
                                <div className="form-group">
                                    <label className="form-label">GST <span className="required-dot">*</span></label>
                                    <input
                                        name="gst"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Company GST No"
                                        onChange={e => formik.setFieldValue('gst', e?.target?.value?.toUpperCase())}
                                        onBlur={formik?.handleBlur}
                                        value={formik?.values?.gst?.toUpperCase()}
                                        disabled={isFormApproved}
                                    />
                                    {formik.touched.gst && formik.errors.gst ? (
                                        <div className="error-msg">{formik.errors.gst}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="gstDocId" className="form-label">Attach copy of GST Certificate <span className="required-dot">*</span></label>
                                    <FileUpload
                                        isDisabled={isFormApproved}
                                        fieldName='gstDocId'
                                        formik={formik}
                                        fileName="GST.pdf"
                                    />
                                    {formik?.touched.gstDocId && formik?.errors.gstDocId ? (
                                        <div className="error-msg">{formik?.errors.gstDocId}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="column-4">
                                <div className="form-group">
                                    <label className="form-label">PAN <span className="required-dot">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter PAN"
                                        name="pan"
                                        onChange={e => formik.setFieldValue('pan', e?.target?.value?.toUpperCase())}
                                        onBlur={formik?.handleBlur}
                                        value={formik?.values?.pan?.toUpperCase()}
                                        disabled={isFormApproved}
                                    />
                                    {formik?.touched.pan && formik?.errors.pan ? (
                                        <div className="error-msg">{formik?.errors.pan}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="panDocId" className="form-label">Attach copy of PAN <span className="required-dot">*</span></label>
                                    <FileUpload
                                        isDisabled={isFormApproved}
                                        fieldName='panDocId'
                                        formik={formik}
                                        fileName="Pan card.pdf"
                                    />
                                    {formik?.touched.panDocId && formik?.errors.panDocId ? (
                                        <div className="error-msg">{formik?.errors.panDocId}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="column-4">
                                <div className="form-group">
                                    <label className="form-label">Employee Code <span className="required-dot">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Employee Code"
                                        name="empCode"
                                        onChange={e => formik.setFieldValue('empCode', e?.target?.value?.toUpperCase())}
                                        onBlur={formik?.handleBlur}
                                        value={formik?.values?.empCode?.toUpperCase()}
                                        disabled={isFormApproved}
                                    />
                                    {formik?.touched.empCode && formik?.errors.empCode ? (
                                        <div className="error-msg">{formik?.errors.empCode}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="empCertDocId" className="form-label">Attach copy of Employee Certificate <span className="required-dot">*</span></label>
                                    <FileUpload
                                        isDisabled={isFormApproved}
                                        fieldName='empCertDocId'
                                        formik={formik}
                                        fileName="ID Card.pdf"
                                    />
                                    {formik?.touched.empCertDocId && formik?.errors.empCertDocId ? (
                                        <div className="error-msg">{formik?.errors.empCertDocId}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="column-4">
                                <div className="form-group">
                                    <label htmlFor="logo" className="form-label">Attach Company Logo <span className="required-dot">*</span></label>
                                    <FileUpload
                                        isDisabled={isFormApproved}
                                        fieldName='logo'
                                        formik={formik}
                                        accept='image/*'
                                        fileName="logo.jpg"
                                    />
                                    {formik?.touched.logo && formik?.errors.logo ? (
                                        <div className="error-msg">{formik?.errors.logo}</div>
                                    ) : null}
                                </div>
                            </div>
                            <p className="note">{note}</p>
                            <ul className="main-btn-group pager">
                                <li>
                                    <button type="submit" disabled={isPutLoading || isFormApproved} className="common-btn btn">
                                        {isPutLoading && <div className="submit-button-loader" />}
                                        Save
                                    </button>
                                </li>
                                <li className="next-btn-wrapper">
                                    <button type="submit" className="line-btn btn nextBtn">
                                        Next
                                        <img src={NextIcon} alt="next icon" className="next-icon" />
                                    </button>
                                </li>

                                {/* CLEAR BUTTON */}
                                {!isFormApproved && (<li onClick={() => formik.resetForm()}>
                                    <button type="button" className="line-btn btn">Clear</button>
                                </li>)}
                            </ul>
                        </form>
                    }
                </div>
            </div>
        </div>
    );
}

export default CompanyDetailsForm;

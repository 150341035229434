import { useEffect } from 'react';
import { NewsFeedIcon } from '../../assets';
import { useDispatch } from 'react-redux';
import { useLazyGetNewsfeedQuery } from '../../api';
import { showToast } from '../../redux/slices';
import Loader from '../loader/Loader';
import NoRecordsFound from '../no-records-found/NoRecordsFound';

interface INewsFeed {
    title: string
    description: string
    url: string
    id: number
}

const NewsFeedBox = () => {
    const dispatch = useDispatch();
    const [getAllNews, { isLoading, data }] = useLazyGetNewsfeedQuery();

    useEffect(() => {

        /* GET NEWS FEED */
        getAllNews({})
            .unwrap()
            .catch((error) => dispatch(showToast({ message: error?.data?.message || 'Error!', type: 'error' })));
    }, [dispatch, getAllNews])

    return (
        <div className="news-feed-section common-p-20 bg-white-border">
            <div className="title-box">
                <div className="icon-box">
                    <img src={NewsFeedIcon} alt="icon" />
                </div>
                <h4>News Feed</h4>
            </div>
            <div className="main-box">
                {isLoading
                    ? <Loader />
                    : data?.body?.length <= 0
                        ? <NoRecordsFound message='No News Available' />
                        : data?.body?.map((news: INewsFeed) => (
                            <div className="news-common-box" key={news?.id}>
                                <h5>{news?.title}</h5>
                                <p>{news?.description}</p>
                                {news?.url && news?.url?.trim() !== "" && <a href={news?.url} className="btn light-btn" rel="noreferrer" title="Read More" target='_blank'>Read More</a>}
                            </div>
                        ))
                }
            </div>
        </div>
    )
}

export default NewsFeedBox;

import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINT } from './endpoints';
import baseQuery from '../baseQuery';

export const apiManagerApi = createApi({
    reducerPath: 'apiManagerApi',

    baseQuery,

    tagTypes: [
        "get_all_api_manager",
    ],

    endpoints: (builder) => ({

        /* GET ALL API MANAGER */
        getAllApiManager: builder.query({
            query: () => ({
                url: ENDPOINT.GET_ALL_API_MANAGER,
                method: "GET",
            }),
            providesTags: ["get_all_api_manager"]
        }),

        /* GET SINGLE API */
        getSingleApiManager: builder.query({
            query: (apiId) => ({
                url: `${ENDPOINT.GET_SINGLE_API_MANAGER}/${apiId}`,
                method: "GET",
            }),
        }),
    }),
})

export default apiManagerApi;

export const {

    /* API MANAGER */
    useLazyGetAllApiManagerQuery,
    useLazyGetSingleApiManagerQuery,

} = apiManagerApi;

interface IProps {
    message?: string
    height?: string
}

const NoRecordsFound = ({ message, height = "400px" }: IProps) => {
    return (
        <div className="no-data-found-container" style={{ height }}>
            <h4 className="no-data-found-text">{message || "No Data Found"}</h4>
        </div>
    )
}

export default NoRecordsFound;

export const ENDPOINT = {

    /* FILE DOWNLOAD */
    GET_DOWNLOAD_FILE: "files/download",

    /* BANK / COMPANY DETAILS */
    GET_COMPANY_DETAILS: "bank/bankdetail",
    PUT_COMPANY_DETAILS: "bank/bankdetail",

    /* BUSINESS CONTACT PERSON */
    GET_CONTACT_PERSONS_DETAILS: "lender",
    GET_SINGLE_PERSON_DETAILS: "lender/contact",
    PUT_CONTACT_PERSON_DETAILS: "lender/contact",
    POST_CONTACT_PERSON_DETAILS: "lender/contact",
    DELETE_CONTACT_PERSON_DETAILS: "lender/contact",

    /* LENDING JOURNEY */
    GET_SAVED_JOURNEY: "lender/journey",
    GET_APPROVED_JOURNEY: "lender/journey/approved",
    POST_LENDING_JOURNEY: "lender/journey",
    PUT_LENDING_JOURNEY_DOCUMENT: "lender/journey/document",

    /* JOURNEY */
    GET_REQUESTED_JOURNEY: "rbih/journey-request",
    POST_REQUEST_JOURNEY: "lender/journey-request",

    /* SUBMIT */
    POST_SUBMIT_FORM: "bank/bankdetail/status",

    /* DESIGNATION */
    GET_DESIGNATIONS: "mst/designation",

    /* API PACKAGES */
    GET_CATEGORIES: "api/category-have-api",
    // GET_CATEGORIES: "api/category",
    PUT_SUBSCRIBE_API: "subscribe/api",
    POST_SUBSCRIBE_API: "subscribe/api",
    GET_SUBSCRIBED_API: "subscribe",
    DELETE_SUBSCRIBED_API: "subscribe/api",

    /* DASHBOARD PROGRESS */
    GET_PROGRESS: "lender/dash-progress",
};
